@charset "utf-8";

/* ======================================================

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

-> scss -> layout -> _header.scss

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

====================================================== */


/* ==================================================
   Header
================================================== */

.l-header {
    // &__bar {
    //     position: fixed;
    //     left: 0;
    //     right: 0;
    //     top: 0;
    //     z-index: 2000;
    //     max-width: 100vw;
    //     background-color: rgba(#fff, 0.96);
    // }
    // &__inner {
    //     @include flex(space-between, center, row, nowrap);
    //     padding-bottom: 15px;
    //     flex: 1;
    //     @include max-width(sm) {
    //         padding: 0;
    //     }
    // }


    &__desc {
        @include flex(center, center, row, nowrap);
        padding: 20px 5%;
        @include font-size(1.4);
        font-weight: bold;
        @include max-width(md) {
          flex-wrap: wrap;
          padding: 20px 80px 0 0;
          @include font-size(1.1);
        }
        @include max-width(sm) {
            width: 100%;
            //display: none;
        }
        p {
            font-weight: bold;
            flex: 1;


          @include max-width(sm) {
            width: 100%;
            flex: auto;
          }
        }
        a {
            border: 1px solid $colorRed;
            border-radius: 3px;
            margin-left: 10px;
            padding: 4px 5px;
            color: $colorRed;
            i {
                margin-right: 3px;
            }

            @include max-width(sm) {
              margin: 0.8em auto 0 0;
          }
        }
    }
    &__top {
        @include flex(flex-end, center, row, nowrap);
        @include max-width(lg) {
            @include flex(flex-start, center, row, nowrap);
        }
        &__menu {
            ul {
                @include flex(flex-end, center, row, nowrap);
                li {
                    width: 100px;
                    text-align: center;
                    margin-right: 15px;
                    a {
                        display: block;
                        width: 100%;
                        @include font-size(1.2);
                        letter-spacing: 0;
                        font-weight: bold;
                    }
                }
            }
        }
        &__right {
            @include max-width(sm) {
                width: 100%;
            }
            &__container {
                @include flex(flex-end, center, row, nowrap);
                padding: 10px 0;
                border-top: 1px solid $colorBase;

                @include max-width(sm) {
                    @include flex(space-between, center, row, nowrap);
                    border: none;
                    //height: 70px;
                }
                .l-header__top__logo {
                    margin-right: 20px;
                    a {
                        @include font-size(3);
                        font-weight: bold;
                        letter-spacing: 0;

                        img{
                          max-width: 120px;
                        }
                    }
                }
                .l-header__top__tel {
                    text-align: center;
                    @include max-width(sm) {
                      //margin-right: 70px;
                    }
                    a {
                        display: block;
                        position: relative;
                        padding-left: 45px;
                        @include font-size(1.4);
                        line-height: 1;
                        letter-spacing: 0;

                        @include max-width(sm) {
                          padding-left: 0;
                        }

                        span {
                            font-size: 2em;
                            white-space: nowrap;
                        }
                    }
                }
            }
        }
    }
    &__global-nav {
        @include max-width(md) {
            display: none;
        }
        .header-m {
            &>ul {
                @include flex( center, center, row, nowrap);
                li {
                    width: calc(100% / 6);
                    border-right: 1px solid $colorBase;
                    &:last-child {
                        border-right: none;
                    }
                    a {
                        display: block;
                        padding: 20px 0;
                        text-align: center;
                        background: $colorMenu;
                        font-weight: bold;
                        @include font-size(2);
                        letter-spacing: 0;
                        line-height: 1;
                        img {
                            display: block;
                            width: 50px !important;
                            height: 50px !important;
                            padding: 10px;
                            margin: 0 auto 14px;
                            border-radius: 50%;
                            background: #fff;
                            line-height: 0;
                        }
                    }
                }
            }
        }
    }
    .c-top-campaign {
        .textwidget {
            @include flex(space-between, center, row, wrap);
            .c-top-campaign__banner {
                width: 49%;
                margin-top: 20px;
                line-height: 0;
                @include max-width(xs) {
                    width: 100%;
                }
            }
        }
    }
}

#hamburger {
    @include flex(center, center, column, nowrap);
    width: 80px;
    height: 60px;
    position: fixed;
    right: 0;
    top: 70px;
    box-sizing: border-box;
    z-index: 10;
    margin-left: 10px;
    background-color: #73A776;
    @include min-width(md) {
        display: none !important;
    }
    @include max-width(sm) {
        top: 10px;
    }

    /*-
    .p-menu-bar{
      position: relative;
      height: 36px;
    }
    span {
        width: 36px;
        height: 2px;
        background: #fff;
        position: absolute;
        left: calc( ( 60px - 36px) / 2);
        &:first-child {
            top: 10px;
        }
        &:nth-child(2) {
            top: calc(50% - 1px);
        }
        &:nth-child(3) {
            bottom: 10px;
        }
    }
    -*/

    img{
      width: 28px;
    }
    b {
      @include font-size(1.2);
      font-weight: 600;
      color: #ffffff;
      margin-top: 0.1em;
    }
    &.active {
        span {
            &:first-child {
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%) rotate(45deg);
            }
            &:nth-child(2) {
                opacity: 0;
            }
            &:nth-child(3) {
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%) rotate(-45deg);
            }
        }
    }
}

#overlay {
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, .2);
    z-index: 100;
    display: none;
}

#drawer-menu {
    width: 260px;
    height: 100vh;
    padding-bottom: 120px;
    overflow-y: scroll;
    background: #fafafa;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 200;
    transform: translateX(320px);
    transition: all 0.3s ease 0s;
    &.open {
        transform: translateX(0);
    }
    .drawer-btn {
        @include flex(center, center, row, nowrap);
        @include font-size(1.8);
        padding: 0.8em 0;
        border-radius: 4px;
        letter-spacing: 0;
        font-weight: 700;
        text-align: center;
        .ja {
            display: block;
        }
        .icon {
            margin-right: 10px;
        }
    }
    .drawer-menu__nav {
        margin: 0;
        padding: 0;
        list-style-type: none;
        li {
            border-bottom: 1px solid #ccc;
            @include font-family(sans-serif);
            a {
                display: block;
                padding: 15px;
                .en {
                    display: inline-block;
                    padding-right: 1em;
                }
                .ja {
                    display: inline-block;
                    font-size: 0.6em;
                }
            }
            &.bnr {
                border-bottom: none;
                margin: 20px 0 0;
                padding: 0 15px;
                &+.bnr {
                    margin-top: 10px;
                }
                a {
                    display: block;
                    padding: 0;
                    img {
                        display: block;
                    }
                }
            }
        }
    }
    .side__content {
        padding: 88px 15px 0;
        &:nth-child(n+2) {
            padding-top: 20px;
        }
    }
    #close {
        position: relative;
        width: 28px;
        height: 28px;
        display: block;
        margin: 10px auto 30px 10px;
        span {
            width: 28px;
            height: 2px;
            background: $colorBase;
            position: absolute;
            left: 50%;
            top: 50%;
            &:first-child {
                transform: translate(-50%, -50%) rotate(45deg);
            }
            &:nth-child(2) {
                transform: translate(-50%, -50%) rotate(-45deg);
            }
        }
    }
}