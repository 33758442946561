@charset "utf-8";

/* ==================================================
   Layout
================================================== */


/* Block
-------------------------------------------------- */


/**
 * Element
 */


/* ==================================================
   Fonts
================================================== */


/*-
@font-face {
font-family: 'Lato';
font-style: normal;
font-weight: normal;
src: local('Lato'), url('../fonts/lato/Lato.woff') format('woff');
}
-*/

@font-face {
    font-family: 'Lusitana';
    font-style: normal;
    font-weight: normal;
    src: local('Lusitana'), url('../fonts/lusitana/Lusitana-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Lusitana';
    font-style: normal;
    font-weight: 600;
    src: local('Lusitana'), url('../fonts/lusitana/Lusitana-Bold.woff') format('woff');
}


/* ==================================================
   Base
================================================== */


/* Mixin
-------------------------------------------------- */

@import "config"; // 基本設定
@import "mixin"; // @mixin

/* Base
-------------------------------------------------- */

@import "./foundation/color"; // カラー指定(よく使うカラーやベースカラーはここで指定)
@import "./foundation/normalize"; // normalize.css
@import "./foundation/base"; // base.css

/* Layout
-------------------------------------------------- */

@import "./layout/header"; // ヘッダーレイアウト
@import "./layout/footer"; //フッターレイアウト
@import "./layout/sidebar"; //サイドバーレイアウト
@import "./layout/common"; // コンテンツ全体のレイアウト

/* Object
-------------------------------------------------- */


/**
 * Component
 */

@import "./object/component/heading"; // 見出しレイアウト
@import "./object/component/button"; // ボタン
@import "./object/component/entry-content"; // WordPressブログ記事レイアウト
@import "./object/component/breadcrumb"; // パンくずリスト

/**
 * Utility
 */

@import "./object/utility/flexbox"; // flexbox
@import "./object/utility/display"; // 要素の表示・非表示

/**
 * Project
 */

@import "yoshida_style";

/* ==================================================
   Pages
================================================== */

#fixed_btn{
  position: fixed;
  bottom: 20px;
  right: 20px;

  @include max-width(sm){
    bottom: 10px;
    right: 10px;
  }

  a{
    position: relative;
    display: block;
    transform: rotate(-36deg);
    transform-origin: left bottom;

    @include max-width(sm){
      width: 17vw;
      max-width: 80px;
      transform: rotate(-20deg);
    }

    img{
      &.package{
        position: relative;
        z-index: 2;
      }
      &.icon{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @include transition(0.4);
        max-width: 80%;
      }
    }

    &:hover{
      opacity: 1;

      img{
        &.icon{
          transform: translate(-50%, -180px);

          @include max-width(sm){
            transform: translate(-50%, -36vw);
          }
        }
      }
    }
  }
}

// ------------------- top --------------------------
.top-slider {
    margin: 20px 0 60px;
    @include max-width(sm) {
        margin: 20px 0;
    }
}

.swiper-container {
    position: relative;
    padding-bottom: 30px;
    .swiper-pagination {
        .swiper-pagination-bullet {
            width: 60px;
            height: 6px;
            border-radius: 0;
            @include max-width(xs) {
                width: 15px;
            }
        }
        .swiper-pagination-bullet-active {
            background: #4DC269;
        }
    }
}

.c-eco {
    margin-top: 60px;
    h2 {
        margin-bottom: 40px;
        text-align: center;
        font-weight: bold;
        @include font-size(2.6);
        letter-spacing: 0;
        small {
            font-size: 75%;
            font-style: normal;
        }
    }
    &__container {
        @include max-width(xs) {
            flex-wrap: wrap;
        }
        .c-eco__item {
            margin: 10px;
            @include max-width(xs) {
                width: 100%;
            }
            img {
                border: 1px solid $colorBorder;
            }
        }
    }
}

.c-eco__msg {
    max-width: 880px;
    padding: 20px 0;
    margin: 60px auto;
    border: 1px solid $colorBorder;
    @include max-width(xs) {
        flex-wrap: wrap;
    }
    & > p {
        flex: 1;
        padding: 0.4em 1em;
        font-weight: bold;
        @include font-size(1.8);
        letter-spacing: 0;
        @include max-width(sm) {
            padding: 10px;
            @include font-size(1.4);

            br{
              display: none;
            }
        }
        @include max-width(xs) {
            flex: none;
            width: 100%;
        }
    }
}

.c-eco__mark {
    padding: 0.6em 0.8em;
    background: $color-scrollbar;
    figure {
        line-height: 0;
        margin-right: 5px;
    }
    p {
        text-align: center;
        font-weight: bold;
        @include font-size(1.5);
        letter-spacing: 0;
    }
}

.l-main__container {
    @include flex(space-between,
    flex-start,
    row,
    wrap);
    @include max-width(sm) {
        flex-direction: column-reverse;
    }
}

.l-main {
    width: calc(100% - 250px);
    @include max-width(sm) {
        width: 100%;
    }
}

.c-product {
    &__header {
      margin-bottom: 1.5em;
      @include font-size(2.4);

        h2 {
          position: relative;
            padding: 1.5em 1em 1.5em 2em;
            border-right: 2px dashed $colorBorder;
            font-weight: bold;
            letter-spacing: 0;
            flex-shrink: 0;
            margin-bottom: 0;

            @include max-width(sm){
              @include font-size(2.0);
            }

            &::before {
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
                content: '';
                display: inline-block;
                width: 1.5em;
                height: 1.5em;
                vertical-align: middle;
                background: url(../images/common/icon1.jpg) no-repeat center / contain;
            }
        }
        p {
            padding-left: 1.5em;
            @include font-size(1.6);
            font-weight: bold;

            @include max-width(sm){
              @include font-size(1.3);

              br{
                display: none;
              }
            }
        }
    }
    &__container {
        ul {
            @include flex(space-between, flex-start, row, wrap);
            li {
                max-width: 49%;
                width: calc( 400px - 15px );
                margin-bottom: 50px;
                @include max-width(xs) {
                    width: 100%;
                }
                a {
                  display: block;
                    & > figure {
                        width: 100%;
                        text-align: center;
                        margin-bottom: 10px;
                        line-height: 0;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
            }
        }
    }
    .p-product-txt {
        p {
            @include font-size(1.3);
            letter-spacing: 0;
        }
        .p-product-meta {
            margin-bottom: 20px;
            .p-product-eco__mark {
                width: 96px;
                padding: 5px;
                margin-right: 12px;
                figure {
                    width: 25px;
                    margin-right: 0;
                }
                p {
                    @include font-size(1.2);
                }
            }
            &__data {
                flex: 1;
                .p-product-meta__tag {
                    width: 96px;
                    margin-bottom: 7px;
                    padding: 3px;
                    text-align: center;
                    border-radius: 2px;
                    @include font-size(1.2);
                    font-weight: bold;
                    background: $colorMain2;
                }
            }
        }
    }
}

.c-other-works {
    letter-spacing: 0;
    .c-works__contact {
        text-align: center;
        padding: 0 0 2em 0;
        @include max-width(xs) {
            padding-bottom: 0;
        }
    }
    .c-works__container {
        max-width: 840px;
        margin-left: auto;
        margin-right: auto;

        .c-works-list {
            width: 100%;
            @include flex(flex-start,
            flex-start,
            row,
            wrap);
            .c-works-box {
                margin-right: 25px;
                width: calc( 33.3333% - 25px);
                @include max-width(md) {
                    width: calc( 50% - 10px);
                    margin-right: 10px;
                }
                @include max-width(xs) {
                    width: 100%;
                    margin-right: 0;
                    margin-top: 40px;
                }
                a {
                    color: inherit;
                    .p-img-box.c-trim {
                        width: 100%;
                        position: relative;
                        overflow: hidden;
                        height: 200px;
                        @include max-width(md) {
                            max-width: 240px;
                            margin: 0 auto;
                        }
                        img {
                            display: block;
                            width: 100%;
                            height: 100%;
                            object-position: center;
                            object-fit: cover;
                            font-family: 'object-fit: cover;'
                        }
                    }
                    .p-text-box {
                        h2 {
                            @include font-size(1.8);
                            color: #46B5D8;
                            padding: 0.75em 0;
                            margin: initial;
                        }
                        .p-text-box__num {
                            display: flex;
                            .p-text {
                                @include font-size(1.2);
                                font-weight: bold;
                                background-color: #DEDEDE;
                                margin-right: 0.8em;
                                padding: 4px 8px 4px 27px;
                                position: relative;
                            }
                            .p-text:before {
                                content: "";
                                background-image: url("../images/works/pin_icon.png");
                                color: #73A776;
                                position: absolute;
                                left: 0.8em;
                                top: 0.6em;
                                width: 1em;
                                height: 1em;
                                display: block;
                                background-size: cover;
                            }
                            .p-num {
                                @include font-size(1.4);
                            }
                        }
                    }
                }
            }
            li:nth-child(3n) {
                margin-right: initial;
            }
            li:nth-child(2n) {
                @include max-width(md) {
                    margin-right: initial;
                }
            }
            li:nth-child(n+4) {
                margin-top: 40px;
            }
            li:nth-child(n+4) {
                margin-top: 40px;
            }
            li:nth-child(n+3) {
                @include max-width(md) {
                    margin-top: 40px;
                }
            }
        }
    }
}

// -------------- product ---------------------------
.c-product-explain {
    max-width: 780px;
    margin-left: auto;
    margin-right: auto;
    &__item {
        margin-bottom: 50px;
        h2 {
            max-width: 210px;
            margin-bottom: 20px;
            padding: 8px 10px;
            background: $colorMain2;
            @include font-size(1.5);
            letter-spacing: 0;
            font-weight: bold;
        }
        ul {
            @include flex(space-between,
            flex-start,
            row,
            wrap);
            li {
                letter-spacing: 0;
                .p-product-explain__img {
                    text-align: center;
                    figcaption {
                        display: inline-block;
                        padding: 0 10px;
                        border-radius: 3px;
                        background: #000;
                        color: #fff;
                        @include font-size(1.6);
                        font-weight: bold;
                        span {
                            @include font-size(1.2);
                            font-weight: normal;
                        }
                    }
                }
                .p-product-explain__cont {
                    @include font-size(1.4);
                    h3 {
                        margin-bottom: 10px;
                        @include font-size(1.4);
                        font-weight: bold;
                    }
                    p {
                        margin-bottom: 20px;
                    }
                }
            }
        }
        .c-product__material {
            li {
                @include flex(space-between,
                flex-start,
                row,
                wrap);
                width: 49%;
                @include max-width(sm) {
                    width: 100%;
                    margin-bottom: 20px;
                }
                .p-product-explain__img {
                    width: 55%;
                    @include max-width(sm) {
                        width: 45%;
                    }
                    @include max-width(xs) {
                        width: 100%;
                    }
                    figcaption {
                        transform: translateY(20%);
                    }
                }
                .p-product-explain__cont {
                    width: 44%;
                    @include max-width(sm) {
                        width: 53%;
                    }
                    @include max-width(xs) {
                        width: 100%;
                    }
                    &.w-100 {
                        width: 100%;
                        max-width: 350px;
                        margin-top: 10px;
                    }
                }
            }
        }
        .c-product__size {
            max-width: 640px;
            margin-left: auto;
            margin-right: auto;
            li {
                width: 25%;
                margin-bottom: 30px;
                @include max-width(xs) {
                    width: 50%;
                    margin-bottom: 0;
                }
                .p-product-explain__img {
                    figcaption {
                        transform: translateY(20%);
                    }
                }
            }
        }
        .c-product__color {
            max-width: 640px;
            margin-left: auto;
            margin-right: auto;
            li {
                width: 32%;
                margin-bottom: 30px;
                @include max-width(xs) {
                    width: 100%;
                    margin-bottom: 0;
                }
                .p-product-explain__img {
                    figcaption {
                        margin: 10px 0;
                    }
                }
            }
        }
    }
}

.c-works-slider {
    padding: 80px 0;
    background: #D2D2D2;
    @include max-width(xs) {
        padding: 50px 0;
    }
    .swiper-slide {
        background: #fff;
        margin-top: 0 !important;
        .p-text-box {
            padding: 0 10px 10px;
        }
    }
    .swiper-pagination {
        bottom: 30px;
        @include max-width(xs) {
            bottom: 15px;
        }
    }
}

.c-sub-contact {
    max-width: 600px;
    margin: 100px auto;
    @include font-size(1.8);
    letter-spacing: 0;
    @include max-width(xs) {
        margin: 60px auto;
    }
    &__cont {
        margin: 1.6em auto;
        @include flex(space-around,
        center,
        row,
        wrap);
        &>p {
            @include font-size(1.6);
            font-weight: bold;
        }
        .c-sub-contact__tel {
            text-align: center;
            width: 100%;
            max-width: 376px;

            a {
                display: block;
                position: relative;
                @include font-size(2.2);
                line-height: 1;
                letter-spacing: 0;

                @include max-width(sm){
                  padding-left: 0;
                  display: inline-block;
                }

                img{
                  width: 100%;
                }

                span {
                    @include font-size(4.2);
                    @include max-width(xs) {
                        @include font-size(3.6);
                    }
                }
            }
        }
    }
    .c-btn-outer{
      margin-top: 0;
    }
}

// --------- product single -------------------------
.c-product__detail {
    &__container {
        @include flex(space-between,
        flex-start,
        row,
        wrap);
        padding-left: 30px;
        @include max-width(md) {
            padding-left: 0;
        }
        .c-product__thumbnail {
            width: 45%;
            @include max-width(xs) {
                width: 100%;
            }

            img{
              height: auto;
            }
        }
        .c-product__detail__cont {
            width: calc(55% - 30px);
            @include max-width(md) {
                width: calc(55% - 15px);
            }
            @include max-width(xs) {
                width: 100%;
            }
            .c-product__images {
                @include flex(flex-start,
                flex-start,
                row,
                wrap);
                max-width: 300px;
                figure {
                    @include flex(center,
                    center,
                    row,
                    nowrap);
                    width: 80px;
                    height: 80px;
                    margin: 0 20px 20px 0;
                    background: $color-scrollbar;
                }
            }
        }
    }
    &__info {
        @include flex(flex-start,
        flex-start,
        row,
        wrap);
        padding-left: 30px;
        letter-spacing: 0;
        @include max-width(md) {
            padding-left: 0;
        }
        .p-product__detail__tag {
            display: inline-block;
            min-width: 100px;
            margin-bottom: 15px;
            padding: 4px 13px;
            text-align: center;
            border: 1px solid #707070;
            @include font-size(1.8);
            font-weight: bold;
            line-height: 1;
        }
        .c-product__detail__color {
            width: 50%;
            margin-top: 60px;
            padding-left: 20px;
            @include max-width(xs) {
                width: 100%;
                padding-left: 0;
            }
            .p-product__color__group {
                @include flex(center,
                flex-start,
                row,
                wrap);
                max-width: 310px;
                .p-product__color__sample {
                    width: 35px;
                    margin: 5px 8px;
                    padding-top: 40px;
                    position: relative;
                    &::before {
                        content: '';
                        width: 35px;
                        height: 35px;
                        position: absolute;
                        top: 0;
                        left: 0;
                        border-radius: 50%;
                    }
                    p {
                        text-align: center;
                        @include font-size(1.4);
                        font-weight: bold;
                        letter-spacing: -0.05em;
                    }
                }
                .color-sample1::before {
                    background: #F51900;
                }
                .color-sample2::before {
                    background: #1229C4;
                }
                .color-sample3::before {
                    background: #FA5B00;
                }
                .color-sample4::before {
                    background: #F0D202;
                }
                .color-sample5::before {
                    background: #2A9B49;
                }
                .color-sample6::before {
                    background: #FA719B;
                }
                .color-sample7::before {
                    background: #3F302D;
                }
                .color-sample8::before {
                    background: #1F4C45;
                }
                .color-sample9::before {
                    background: #F1EFA5;
                }
                .color-sample10::before {
                    background: #FFFFFF;
                    border: 1px solid #ddd;
                }
                .color-sample11::before {
                    background: #000000;
                }
                .color-sample12::before {
                    background: #B2B2B2;
                }
            }
            .p-product__color__txt {
                margin: 10px 0;
                @include font-size(1.4);
            }
            a {
                @include font-size(1.5);
                text-decoration: underline;
                color: $colorRed;
            }
        }
        .c-product__detail__properties {
            width: 50%;
            margin-top: 60px;
            padding-left: 20px;
            @include max-width(xs) {
                width: 100%;
                padding-left: 0;
            }
            .p-product__detail__property-item {
                display: inline-block;
                min-width: 42px;
                margin: 0 13px 15px 0;
                padding: 5px 8px;
                text-align: center;
                border-radius: 3px;
                background: $colorMenu;
                @include font-size(1.6);
                font-weight: bold;
                line-height: 1;
                span {
                    @include font-size(1.2);
                    font-weight: normal;
                }
            }
        }
        .c-product__detail__price {
            width: 100%;
            margin: 60px 0;
            padding: 0 15px;
            @include max-width(md) {
                padding: 0;
            }
            .p-product__detail__tag {
                margin-left: 5px;
            }
            table {
                width: 100%;
                margin-bottom: 30px;
                text-align: center;
                letter-spacing: -0.1em;
                @include font-size(1.6);
                th,
                td {
                    width: 25%;
                    padding: 2px;
                    border: 1px solid #fff;
                }
                thead {
                    font-weight: bold;
                    tr {
                        td {
                            background: $colorBorder;
                            span {
                                @include font-size(1.2);
                                font-weight: normal;
                            }
                        }
                        th {
                            background: $colorMain2;
                        }
                    }
                }
                tbody {
                    tr {
                        th {
                            font-weight: bold;
                        }
                    }
                    tr:nth-child(odd) {
                        background: #9E9C9B;
                    }
                    tr:nth-child(even) {
                        background: #DEDEDE;
                    }
                }
            }
        }
    }
}

.c-product-eco {
    padding-left: 30px;
    .p-product-eco__title {
        display: inline-block;
        width: 210px;
        padding: 8px 10px;
        @include font-size(1.5);
        font-weight: bold;
        background: $colorMain2;
    }
    .c-eco__container {
        flex-wrap: wrap;
        figure {
            width: 100%;
            text-align: center;
        }
    }
    .c-eco__msg {
        margin-top: 0;
        border: none;
    }
}

// --------- works single -------------------------
.c-sub-header {
    padding: 1.2em 1em 1.2em 2.4em;
    position: relative;
    border: 2px dashed #73A776;
    color: #73A776;
    @include font-size(3.0);

    @include max-width(sm){
      padding: 0.8em 1.2em 0.8em 2.4em;
    }

    @include max-width(xs) {
      @include font-size(2.0);
    }
    &::before {
        content: '';
        width: 1.2em;
        height: 1.2em;
        position: absolute;
        top: -2px;
        left: -2px;
        background-image: url(../images/common/sub-title-border.jpg);
        background-repeat: no-repeat;
        background-size: cover;
    }
    &::after {
        content: '';
        width: 1.2em;
        height: 1.2em;
        position: absolute;
        right: -2px;
        bottom: -2px;
        background-image: url(../images/common/sub-title-border.jpg);
        background-repeat: no-repeat;
        background-size: cover;
    }
    span {
        position: absolute;
        left: 0.8em;
        top: 50%;
        transform: translateY(-50%);
        line-height: 0;
        width: 1.2em;
    }
}
.c-works-archive__list{
  @include flex(flex-start, flex-start, row, wrap);

  .p-content-box {
    width: calc( 33% - 16px );
    margin-bottom: 24px;
    margin-right: 24px;

    @include max-width(xs) {
      width: 100%;
      margin-right: 0;
    }

    &:nth-child(3n){
      margin-right: 0;
    }

    h2 {
        margin: 15px 0;
        @include font-size(1.8);
        color: #46B5D8;
    }
    .p-text-box__num {
      display: flex;
      .p-text {
        margin-right: 0.8em;
        padding: 4px 8px 4px 25px;
        position: relative;
        @include font-size(1.2);
        font-weight: bold;
        background: #DEDEDE;
        &::before {
          content: "";
          width: 1.2em;
          height: 1.2em;
          position: absolute;
          left: 0.5em;
          top: 50%;
          transform: translateY(-50%);
          background: url(../images/works/pin_icon.png) no-repeat center / contain;
        }
      }
      .p-num {
          @include font-size(1.4);
      }
    }
  }
}
.c-works-single__container {
    .c-works__contact {
        padding: 0;
    }
    .c-works-single__cont {
        @include flex(space-between,
        flex-start,
        row,
        wrap);
        margin-bottom: 30px;
        padding: 20px;
        letter-spacing: 0;
        @include max-width(md) {
            padding: 20px 0;
        }
        .p-content-box {
            width: 33%;
            margin-bottom: 30px;
            @include max-width(xs) {
                width: 100%;
            }
            h2 {
                margin: 15px 0;
                @include font-size(1.8);
                color: #46B5D8;
            }
            .p-text-box__num {
                display: flex;
                .p-text {
                    margin-right: 0.8em;
                    padding: 4px 8px 4px 25px;
                    position: relative;
                    @include font-size(1.2);
                    font-weight: bold;
                    background: #DEDEDE;
                    &::before {
                        content: "";
                        width: 1.2em;
                        height: 1.2em;
                        position: absolute;
                        left: 0.5em;
                        top: 50%;
                        transform: translateY(-50%);
                        background: url(../images/works/pin_icon.png) no-repeat center / contain;
                    }
                }
                .p-num {
                    @include font-size(1.4);
                }
            }
        }
        .p-single-data {
            width: 60%;
            margin-bottom: 30px;
            @include max-width(md) {
                width: 65%;
            }
            @include max-width(xs) {
                width: 100%;
            }
            &__container {
                max-width: 400px;
                margin-bottom: 50px;
                dl {
                    display: flex;
                    dt {
                        width: 40%;
                        padding: 7px 12px;
                        @include font-size(1.4);
                        font-weight: bold;
                        line-height: 1;
                        border-top: 1px solid #fff;
                        border-right: 1px solid #fff;
                    }
                    dd {
                        flex: 1;
                        padding: 7px 12px;
                        @include font-size(1.3);
                        line-height: 1;
                        border-top: 1px solid #fff;
                        font-weight: 500;
                    }
                }
                dl:nth-of-type(2n+1) {
                    dt,
                    dd {
                        background: #DEDEDE;
                    }
                }
                dl:nth-of-type(2n) {
                    dt,
                    dd {
                        background: #9E9C9B;
                    }
                }
            }
        }
    }
    .c-works-single__subcont {
        max-width: 720px;
        margin-left: auto;
        margin-right: auto;
        @include font-size(1.4);
        letter-spacing: 0;
        p {
            @include font-size(1.4);
            letter-spacing: 0;
        }
        .c-btn-outer {
            margin-top: 60px;
            .c-btn::before {
                content: "\f053";
                position: absolute;
                left: 15px;
                top: 50%;
                transform: translateY(-50%);
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
            }
        }
    }
}

.c-sub-works__title {
    display: inline-block;
    width: 220px;
    padding: 8px 10px;
    position: relative;
    z-index: 2;
    @include font-size(1.5);
    font-weight: bold;
    letter-spacing: 0;
    background: $colorMain2;
    transform: translateY(50%);
}

// --------------- estimate -------------------------
.c-estimate-form {
    &__container {
        max-width: 760px;
        padding: 30px;
        margin: auto;
        background: #eee;
        @include max-width(md) {
            padding: 30px 15px;
        }
        .c-must {
            margin-bottom: 20px;
            padding-left: 20px;
            @include font-size(1.4);
            color: $colorRed;
        }
        dl {
            @include flex(space-between,
            center,
            row,
            wrap);
            letter-spacing: 0;
            dt {
                width: 220px;
                padding: 10px 20px;
                @include font-size(1.5);
                font-weight: bold;
                @include max-width(md) {
                    padding: 10px 0;
                }
                @include max-width(xs) {
                    width: 100%;
                }
                span {
                    @include font-size(1.0);
                    color: $colorRed;
                }
            }
            dd {
                width: calc( 100% - 220px );
                padding: 10px 20px;
                background: #BEBEBE;
                @include font-size(1.4);
                @include max-width(xs) {
                    width: 100%;
                    padding: 10px 0;
                    background: none;
                }
                input {
                    height: 27px;
                    padding: 5px 10px;
                    box-sizing: border-box;
                    max-width: 100%;
                }
                textarea {
                    width: 100%;
                    height: 200px;
                    padding: 10px;
                    box-sizing: border-box;
                }
                .address-text {
                    width: 100%;
                    margin-top: 15px;
                }
                #zip1,
                #zip2{
                  display: inline-block;
                  width: 6em;
                  margin: 0 0.5em;
                }
                input[name="number"]{
                  width: 6em;
                  margin-right: 0.5em;
                }
                .p-input-75 {
                    width: 75%;
                }
                p {
                    margin-top: 10px;
                    @include font-size(1.2);
                }
            }
        }
    }
    .c-btn-outer {
        text-align: center;
        margin: 60px 0;

        input[type="submit"] {
          @extend .c-btn;
          @include transition;
          cursor: pointer;
        }
    }
}

// ---------------- guide ---------------------------
.c-guide {
    margin-top: 6em;
    letter-spacing: 0;
    @include max-width(xs) {
        margin-top: 3em;
    }
    &>h2 {
        text-align: center;
        @include font-size(3.0);
        font-weight: bold;
        @include max-width(xs) {
            @include font-size(2.4);
            margin-bottom: 0;
        }
    }
    &-list {
        @include flex(space-between,
        flex-start,
        row,
        wrap);
        li {
            width: 47%;
            margin-bottom: 100px;
            @include max-width(sm) {
                width: 100%;
                margin-bottom: 40px;
            }
            .c-guide-list__header {
                @include flex(center,
                center,
                row nowrap);
                .p-point-num {
                    @include flex(center,
                    center,
                    row,
                    nowrap);
                    width: 100px;
                    height: 100px;
                    margin-right: 20px;
                    text-align: center;
                    @include font-size(2.6);
                    line-height: 1;
                    background: url(../images/guide/point_bg.svg) center / 100% 100%;
                    @include max-width(xs) {
                        width: 70px;
                        height: 70px;
                        @include font-size(1.8);
                    }
                }
                h3 {
                    margin: 0;
                    text-align: center;
                    @include font-size(2.2);
                    font-weight: bold;
                    @include max-width(xs) {
                        width: calc(100% - 90px);
                        @include font-size(1.8);
                    }
                    span {
                        font-size: 90%;
                    }
                }
            }
            .c-guide-list__cont {
                margin-top: 40px;
                p {
                    margin-top: 30px;
                    @include font-size(1.3);
                    font-weight: bold;
                }
            }
        }
        li:first-child() {
            width: 100%;
            .c-eco {
                &>p {
                    max-width: 610px;
                    margin: auto;
                    @include font-size(1.7);
                }
            }
        }
    }
}

.c-guide-voice {
    max-width: 840px;
    margin: 100px auto 0;
    padding: 60px 40px;
    background: #D2D2D2;
    @include max-width(xs) {
        padding: 60px 15px;
    }
    .c-guide-voice__title {
        text-align: center;
        @include font-size(2.5);
        font-weight: bold;
    }
    &__container {
        margin-top: 15px;
        ul {
            margin-bottom: 70px;
            padding: 0 20px;
            position: relative;
            background: #fff;
            border: 1px solid #707070;
            @include max-width(xs) {
                padding: 0 10px;
            }
            &::before {
                content: '';
                position: absolute;
                bottom: -40px;
                left: calc(50% - 30px);
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 40px 30px 0 30px;
                border-color: #707070 transparent transparent transparent;
            }
            &::after {
                content: '';
                position: absolute;
                bottom: -38px;
                left: calc(50% - 30px);
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 40px 30px 0 30px;
                border-color: #fff transparent transparent transparent;
            }
            li {
                @include flex(space-between,
                center,
                row,
                nowrap);
                margin: 30px 0;
                figure {
                    @include flex(center,
                    center,
                    row,
                    nowrap);
                    width: 80px;
                    height: 80px;
                    margin: 0 40px 0 20px;
                    background: #D2D2D2;
                    border-radius: 50%;
                    line-height: 0;
                    @include max-width(xs) {
                        width: 50px;
                        height: 50px;
                        margin: 0 15px 0 0;
                        img {
                            width: 70%;
                        }
                    }
                }
                .p-voice-work {
                    flex: 1;
                    border: 1px solid;
                    padding: 5px;
                    @include font-size(1.8);
                    font-weight: 500;
                    letter-spacing: 0;
                }
            }
        }
        .c-btn-outer {
            .c-btn::after {
                content: '\f054';
                position: absolute;
                right: .5em;
                top: 50%;
                transform: translateY(-50%);
                font-family: 'Font Awesome 5 Free';
                font-weight: 900;
            }
        }
    }
}

// ----------------- voice --------------------------
.c-voice__container {
    @include flex(flex-start,
    flex-start,
    column-reverse,
    wrap);
}

.c-voice {
    width: 100%;
    letter-spacing: 0;
    ul {
        max-width: 730px;
        margin: auto;
        li {
            @include flex(space-between,
            center,
            row,
            nowrap);
            margin-top: 2em;

            &:last-child{
              margin-top: 6em;
            }

            @include max-width(xs) {
                @include flex(center,
                center,
                row,
                wrap);
            }
            .p-voice-user {
                @include flex(center,
                center,
                column,
                nowrap);
                width: 90px;
                margin-right: 1em;

                @include max-width(sm){
                  margin-right: 0;
                  margin-bottom: 1em;
                }

                .p-voice-img {
                    @include flex(center,
                    center,
                    row,
                    nowrap);
                    margin-bottom: 0.5em;
                    width: 80px;
                    height: 80px;
                    border-radius: 50%;
                    background: #D2D2D2;
                    line-height: 0;
                }
                figcaption {
                    @include font-size(1.8);
                    font-weight: 500;
                }
            }
            .p-voice-content {
                flex: 1;
                @include max-width(xs) {
                    flex: none;
                    width: 100%;
                }
                .p-voice-txt {
                    margin-bottom: 2em;
                    padding: 20px;
                    position: relative;
                    border: 1px solid #707070;
                    &::before {
                        content: '';
                        position: absolute;
                        top: 100%;
                        left: calc(50% - 20px);
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 20px 14px 0 14px;
                        border-color: #707070 transparent transparent transparent;
                    }
                    &::after {
                        content: '';
                        position: absolute;
                        top: calc( 100% - 2px );
                        left: calc(50% - 20px);
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 20px 14px 0 14px;
                        border-color: #fff transparent transparent transparent;
                    }
                    p {
                        @include font-size(1.8);
                        font-weight: 500;
                    }
                }
                .p-voice-link {
                    display: block;
                    text-align: center;
                    @include font-size(1.8);
                    font-weight: bold;
                    color: $colorRed;
                    text-decoration: underline;
                }
                .c-sub-contact {
                    max-width: 100%;
                    margin: 0;
                    padding: 30px 20px 0;
                    border: 1px solid #707070;
                    .bold {
                        margin-bottom: 1em;
                        font-weight: bold;

                        @include max-width(sm){
                          text-align: center;
                        }

                        & + p{
                          @include max-width(sm){
                            font-size: 0.8em;

                            br{
                              display: none;
                            }
                          }
                        }
                    }
                    .c-btn-outer {
                        margin-bottom: 30px;
                    }
                }
            }
        }
    }
}

.c-faq {
    width: 100%;
    letter-spacing: 0;
    ul {
        border-top: 5px solid #73A776;
        border-bottom: 5px solid #73A776;
        li {
            .c-faq-question {
                @include flex(space-between,
                center,
                nowrap);
                padding: 14px 50px 14px 20px;
                position: relative;
                cursor: pointer;
                border-bottom: 1px solid #73A776;
                @include max-width(xs) {
                    padding: 14px 35px 14px 5px;
                }
                &::after {
                    content: '✛';
                    position: absolute;
                    right: 15px;
                    top: 50%;
                    transform: translateY(-50%);
                    @include font-size(2.6);
                    color: #707070;
                    @include max-width(xs) {
                        right: 5px;
                    }
                }
                p {
                    color: #707070;
                    font-weight: bold;
                    @include font-size(1.8);

                    @include max-width(sm){
                      @include font-size(1.5);
                    }
                }
                .p-faq-mark {
                    margin-right: 30px;
                    font-size: 2em;
                    @include max-width(xs) {
                        margin-right: 15px;
                    }
                }
                .p-faq-question-txt {
                    flex: 1;
                }
            }
            .c-faq-question.active::after {
                content: '―';
            }
            .p-faq-answer {
                display: none;
                padding: 20px;
                background: #73A776;
                color: #fff;
                @include font-size(1.8);

                @include max-width(sm){
                  @include font-size(1.5);
                }
            }
        }
    }
}

// --------------- about ----------------------------
.c-company {
    .c-company__container {
        max-width: 660px;
        margin: 100px auto 0;
        .c-company__title {
            margin-left: 8px;
            margin-bottom: 15px;
            @include font-size(1.5);
            font-weight: bold;
            letter-spacing: 0;
        }
        .c-company__table {
            border-top: 2px dashed #707070;
            letter-spacing: 0;
            dl {
                @include flex(space-between,
                center,
                row,
                wrap);
                border-bottom: 2px dashed #707070;
                min-height: 70px;
                padding: 10px 8px;
                @include font-size(1.4);
                dt {
                    text-align: center;
                    font-weight: bold;
                }
                dd {
                    max-width: 450px;
                }
            }
        }
        .c-company__overview__container {
            dl {
                dt {
                    width: 60px;
                }
                dd {
                    width: calc(100% - 90px);
                }
            }
        }
        .c-company__commercial__container {
            dl {
                dt {
                    width: 105px;
                }
                dd {
                    width: calc(100% - 135px);
                }
            }
        }
    }
    .c-company__commercial {
        .c-company__title {
            @include font-size(2.0);
        }
    }
}

.c-equipment {
    &__container {
        max-width: 700px;
        margin: 60px auto;
        letter-spacing: -0.04em;
        h3 {
            margin-bottom: 60px;
            text-align: center;
            @include font-size(1.6);
            font-weight: bold;
        }
        .c-equipment-cont {
            @include flex(space-between,
            center,
            row,
            wrap);
            .p-equipment-img {
                width: 35%;
                line-height: 0;
                @include max-width(xs) {
                    width: 100%;
                    margin-bottom: 15px;
                    text-align: center;
                }
            }
            p {
                width: 62%;
                @include font-size(1.2);
                @include max-width(xs) {
                    width: 100%;
                }
            }
        }
    }
    .c-process {
        h3 {
            margin-bottom: 60px;
            text-align: center;
            @include font-size(1.6);
            font-weight: bold;
        }
        &__container {
            background: #D2D2D2;
            padding-bottom: 60px;
            .c-process-item {
                background: #fff;
                letter-spacing: 0;
                opacity: 0.65;
                figure {
                    line-height: 0;
                }
                .c-process-txt {
                    padding: 10px;
                    p {
                        @include font-size(1.2);
                    }
                    .p-process-num {
                        @include font-size(1.6);
                        margin-bottom: 10px;
                        font-weight: bold;
                        text-decoration: underline;
                    }
                }
            }
            .c-process-item.swiper-slide-active {
                opacity: 1;
            }
        }
    }
    .c-step {
        text-align: center;
        margin-top: 60px;
    }
}

// ---------privacypolicy----------------------------
.c-privacy__container {
    h2 {
        @include font-family(serif);
        border-bottom: 1px solid #A29369;
        margin-top: 0.95em;
    }
    ol {
        margin: 40px 0;
        counter-reset: number;
    }
    ol li {
        position: relative;
        @include font-size(1.4);
        padding-left: 1.4em;
        margin: 1.2em 0;
    }
    ol li:before {
        position: absolute;
        left: 0;
        counter-increment: number;
        content: counter(number) ".";
        font-size: 1.1em;
        display: block;
    }
    ul {
        margin: 40px 0;
    }
    ul li {
        position: relative;
        @include font-size(1.4);
        padding-left: 1.4em;
        margin: 1.2em 0;
    }
    ul li:before {
        position: absolute;
        left: 0;
        top: 0.5em;
        content: " ";
        width: 0.5em;
        height: 0.5em;
        background: #202121;
        display: block;
    }
    p {
        @include font-size(1.4);
    }
}