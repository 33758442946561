@charset "utf-8";

/* ======================================================

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

-> scss -> _mixin.scss

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

====================================================== */

@mixin min-width($breakpoint: md) {
    @media screen and (min-width: #{map-get($mq-min-width, $breakpoint)}#{$mq-unit}) {
        @content;
    }
}

@mixin max-width($breakpoint: md) {
    @media screen and (max-width: #{map-get($mq-max-width, $breakpoint)}#{$mq-unit}) {
        @content;
    }
}

@mixin max($width) {
    @media only screen and (max-width: $width#{$mq-unit}) {
        @content;
    }
}

@mixin and($minWidth, $maxWidth) {
    @media only screen and (min-width: $minWidth#{$mq-unit}) and (max-width: $maxWidth#{$mq-unit}) {
        @content;
    }
}

@mixin min($width) {
    @media only screen and (min-width: $width#{$mq-unit}) {
        @content;
    }
}

@mixin tablet($width, $height) {
    @media only screen and (device-width: $width#{$mq-unit}) and (device-height: $height#{$mq-unit}) {
        @content;
    }
}

@mixin device($width, $height) {
    @media only screen and (device-width: $width#{$mq-unit}) and (device-height: $height#{$mq-unit}) {
        @content;
    }
}


/**
 * Mediaquery Display
 */

@each $breakpoint,
$property in $mq-min-width {
    .min-#{$breakpoint} {
        @include max-width(#{$breakpoint}) {
            display: none !important;
        }
    }
    .max-#{$breakpoint} {
        @include min-width(#{$breakpoint}) {
            display: none !important;
        }
    }
}


/**
 * Clearfix
 */

@mixin cf {
    clear: both;
    content: "";
    display: block;
}


/**
 * Prefix
 */

@mixin prefix($property, $value) {
    @each $prefix in -webkit-,
    -moz-,
    -ms-,
    -o-,
    "" {
        #{$prefix}#{$property}: $value;
    }
}


/**
 * Position Center（親要素に対して上下中央寄せ）
 */

@mixin position-center($top: 50%, $left: 50%, $translateX: -50%, $translateY: -50%) {
    left: $left;
    position: absolute;
    top: $top;
    transform: translate($translateX, $translateY);
}


/**
 * Flexbox
 */

@mixin flex($justify: flex-start, $align: flex-start, $direction: column, $wrap: nowrap) {
    width: 100%;
    align-items: $align;
    display: flex;
    flex-direction: $direction;
    justify-content: $justify;
    flex-wrap: $wrap;
}


/**
 * CSS grid
 */

@mixin grid-container($column-count: 3, $column-gap: 0) {
    column-count: $column-count;
    column-gap: $column-gap;
}

@mixin grid-item {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
}


/**
 * Color
 */

@mixin color($property, $value, $color: $colorBase, $opacity: 1.0) {
    #{$property}: #{$value} rgba($color, $opacity);
}


/**
 * Font size
 */

@mixin font-size($size: $font-size-init) {
    font-size: ($size*10) + px;
    font-size: $size + rem;
}


/**
 * Font-family
 */

@mixin font-family($fontname: sans-serif, $fontweight:400) {
    font-family: #{map-get( map-get($fonts, $fontname),fontname )};
    font-weight: #{map-get( map-get($fonts, $fontname),fontweight )};
}


/**
 * Transition
 */

@mixin transition($time: 0.16) {
    -webkit-transition: $time + s cubic-bezier(0.170, 0.935, 0.305, 1.000);
    transition: $time + s cubic-bezier(0.170, 0.935, 0.305, 1.000);
}


/**
 * Calc
 */

@mixin calc($property, $expression) {
    #{$property}: -webkit-calc(#{$expression});
    #{$property}: calc(#{$expression});
}


/**
 * Inner
 */

@mixin inner($val: $inner-max-width, $padding: $inner-pd) {
    width: 100%;
    max-width: calc( #{$val}px + #{$padding} * 2);
    box-sizing: border-box;
    padding-left: #{$padding};
    padding-right: #{$padding};
    margin-left: auto;
    margin-right: auto;
}


/**
 * Image-trim
 */

@mixin c-trim {
    /*-画像を内包する親要素-*/
    width: 100%;
    position: relative;
    overflow: hidden;
}

@mixin c-trim__after($val: ld) {
    /*-c-trim の疑似要素に指定-*/
    content: '';
    display: block;
    width: 100%;
    padding-bottom: #{map-get($image-trim-pb, $val)};
    /*-画像の縦横比を指定 初期値は75%（LDサイズ）-*/
}

@mixin c-trim__inner {
    /*-c-trim の直下にあるdivまたはaタグに指定-*/
    position: absolute !important;
    width: 100%;
    height: 100%;
}

@mixin c-trim__img {
    /*-トリミングしたい画像や要素に指定*/
    display: block;
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
    font-family: 'object-fit: cover;'
}

@each $size,
$val in $image-trim-pb {
    .c-trim.#{$size} {
        @include c-trim;
        &:after {
            @include c-trim__after($size);
        }
    }
}

.c-trim__inner {
    @include c-trim__inner;
}

.c-trim__after {
    @include c-trim__img;
}


/**
 * Gap-margin
 */

@mixin gap-mg($dir: top, $val: 2.0em) {
    &:nth-of-type(n+2) {
        margin-#{$dir}: $val;
    }
}


/**
 * Gap-padding
 */

@mixin gap-pd($dir: top, $val: 2.0em) {
    &:nth-child(n+2) {
        margin-#{$dir}: $val;
    }
}