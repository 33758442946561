@charset "utf-8";

/* ======================================================

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

-> scss -> layout -> _footer.scss

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

====================================================== */


/* ==================================================
   Footer
   ================================================== */

.l-footer {
    margin-top: 6em;
    @extend .section;
    background: #E0ECE1;
    letter-spacing: 0;
    &__container {
        @include flex(space-between, flex-start, row, wrap);
        .c-footer-left {
            width: 280px;
            @include max-width(md) {
                width: 100%;
            }
            a {
                @include font-size(3.0);
                font-weight: bold;
            }
            p {
                margin: 30px 0;
                @include font-size(1.4);
            }
        }
        .c-footer-right {
            width: 420px;
            @include max-width(sm) {
                margin-top: 30px;
            }
            ul {
                height: 150px;
                @include flex(space-between, flex-start, column, wrap);
                @include max-width(xs) {
                    height: 230px;
                }
                li {
                    width: 130px;
                    margin-bottom: 15px;
                    @include font-size(1.3);
                    font-weight: bold;
                }
            }
            .p-copyright {
                @include font-size(1.2);
                margin-top: 2em;
            }
        }
        .c-footer-map {
            width: calc(100% - 750px);
            @include max-width(md) {
                width: calc(100% - 450px);
            }
            @include max-width(sm) {
                width: 100%;
            }
        }
    }
}