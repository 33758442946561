@charset "utf-8";

/* ======================================================

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

-> scss -> base -> _color.scss

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

====================================================== */

$colorBase: #040102;
$colorMain: #73A776;
$colorMain2: #F3CB01;
$colorMain3: #C8B8A5;
$colorMainspan: #D5DBDB;
$colorMain4: #9DC02E;
$colorBorder: #dedede;
$colorRed: #F90F04;
$colorMenu: #EEE;

/* ==================================================
   Color Stylez
================================================== */


/**
 * SNS Color
 */

$colorLine: #1dcd00;
$colorFacebook: #3B5998;
$colorTwitter: #55acee;
$colorGoogle: #dd4b39;
$colorInstagram: #3f729b;

/* ==================================================
   Color Palette Style
================================================== */

//link
$color-link: $colorBase;
$color-link-hover: $colorMain;
//common
$color-selection: #dedede;
$color-scrollbar: #efefef;
//header
$color-header-btn: black;
//toggle-menu
$color-toggle-hamburger-bg: transparent;
$color-toggle-hamburger-bar: black;
$color-toggle-border: $colorMain;
$color-toggle-bg--open: $colorMain;
$color-toggle-main-text: $colorBase;
$color-toggle-sub-text: $colorBase;
//breadcrumb
$color-breadcrumb-bg: transparent;
//footer
$color-footer-copy: $colorMain;
$color-footer-heading: $colorMain;
//heading
$color-heading: black;
//button
$color-btn: $colorMain;