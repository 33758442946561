@charset "utf-8";

/* ======================================================

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

-> scss -> layout -> _sidebar.scss

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

====================================================== */


/* ==================================================
   Sidebar
================================================== */

.l-side {
    width: 220px;
    @include max-width(sm) {
        width: 100%;
        margin-top: 60px;
    }
    .c-side-eco__mark {
        margin-right: 0;
    }
    .widget {
        margin-top: 20px;
        .widget-ttl {
            padding: 10px;
            margin-bottom: 20px;
            @include font-size(1.5);
            letter-spacing: 0;
            line-height: 1.35;
            font-weight: bold;
            background: $colorMain2;
        }
        ul {
            li {
                margin-bottom: 5px;
                position: relative;
                border: 1px solid $colorBorder;
                border-bottom: 1px solid $colorMain2;

                a {
                  padding: 0.5em 0.5em 0.5em 4em;
                    position: relative;
                    display: block;
                    height: 100%;
                    @include font-size(1.3);
                    font-weight: bold;
                    letter-spacing: 0;

                    img{
                      position: absolute;
                      left: 0.5em;
                      top: 50%;
                      transform: translateY(-50%);
                      z-index: 20;
                      width: 2.8em;
                      height: 2.8em;
                      border-radius: 50%;
                      background-color: #fff;
                      border: 2px solid $colorBorder;
                    }
                }
            }
        }
    }
    .c-side-banner {
        @include flex(center, flex-start, row, wrap);
        li {
            margin-top: 20px;
        }
    }
}