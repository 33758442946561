@charset "utf-8";

/* ======================================================

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

-> scss -> module -> _breadcrumb.scss

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

====================================================== */

/* ==================================================
   Bread Crumb
   ================================================== */

.c-breadcrumb{
  padding: 20px 0;

  ul{

    li{
      @include font-size(1.3);
      display: inline;

      a{
        &:hover{
          text-decoration: none;
        }
      }

      i{
        margin-left: 10px;
      }
    }
  }
}
