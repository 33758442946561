@charset "utf-8";

/* ======================================================

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

-> scss -> layout -> _common.scss

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

====================================================== */

/* ==================================================
   Common Content
   ================================================== */

#wrap {
  position: relative;
  overflow: hidden;
}
#contents {
  position: relative;
}

.inner {
  @include inner;
}

.section {
  padding: 120px 0;

  @include max-width(md){
    padding: 60px 0;
  }

  &--top{
    @extend .section;
    padding-top: 0;
  }
}

.c-block{
  display: inline-block;
}
