@charset "UTF-8";
/* ==================================================
   Layout
================================================== */
/* Block
-------------------------------------------------- */
/**
 * Element
 */
/* ==================================================
   Fonts
================================================== */
/*-
@font-face {
font-family: 'Lato';
font-style: normal;
font-weight: normal;
src: local('Lato'), url('../fonts/lato/Lato.woff') format('woff');
}
-*/
@font-face {
  font-family: 'Lusitana';
  font-style: normal;
  font-weight: normal;
  src: local("Lusitana"), url("../fonts/lusitana/Lusitana-Regular.woff") format("woff");
}

@font-face {
  font-family: 'Lusitana';
  font-style: normal;
  font-weight: 600;
  src: local("Lusitana"), url("../fonts/lusitana/Lusitana-Bold.woff") format("woff");
}

/* ==================================================
   Base
================================================== */
/* Mixin
-------------------------------------------------- */
/* ======================================================

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

-> scss -> _config.scss

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

====================================================== */
/**
 * Mediaquery
 */
/**
 * Font Size
 */
/**
 * Font Family
 */
/**
 * Inner
 */
/**
 * Image-trim
 */
/**
 * border-radius
 */
/* ======================================================

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

-> scss -> _mixin.scss

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

====================================================== */
/**
 * Mediaquery Display
 */
@media screen and (max-width: 1199px) {
  .min-xlg {
    display: none !important;
  }
}

@media screen and (min-width: 1200px) {
  .max-xlg {
    display: none !important;
  }
}

@media screen and (max-width: 1079px) {
  .min-lg {
    display: none !important;
  }
}

@media screen and (min-width: 1080px) {
  .max-lg {
    display: none !important;
  }
}

@media screen and (max-width: 991px) {
  .min-md {
    display: none !important;
  }
}

@media screen and (min-width: 992px) {
  .max-md {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  .min-sm {
    display: none !important;
  }
}

@media screen and (min-width: 768px) {
  .max-sm {
    display: none !important;
  }
}

@media screen and (max-width: 479px) {
  .min-xs {
    display: none !important;
  }
}

@media screen and (min-width: 480px) {
  .max-xs {
    display: none !important;
  }
}

/**
 * Clearfix
 */
/**
 * Prefix
 */
/**
 * Position Center（親要素に対して上下中央寄せ）
 */
/**
 * Flexbox
 */
/**
 * CSS grid
 */
/**
 * Color
 */
/**
 * Font size
 */
/**
 * Font-family
 */
/**
 * Transition
 */
/**
 * Calc
 */
/**
 * Inner
 */
/**
 * Image-trim
 */
.c-trim.2ls {
  /*-画像を内包する親要素-*/
  width: 100%;
  position: relative;
  overflow: hidden;
}

.c-trim.2ls:after {
  /*-c-trim の疑似要素に指定-*/
  content: '';
  display: block;
  width: 100%;
  padding-bottom: 100%;
  /*-画像の縦横比を指定 初期値は75%（LDサイズ）-*/
}

.c-trim.ld {
  /*-画像を内包する親要素-*/
  width: 100%;
  position: relative;
  overflow: hidden;
}

.c-trim.ld:after {
  /*-c-trim の疑似要素に指定-*/
  content: '';
  display: block;
  width: 100%;
  padding-bottom: 75%;
  /*-画像の縦横比を指定 初期値は75%（LDサイズ）-*/
}

.c-trim.lvh {
  /*-画像を内包する親要素-*/
  width: 100%;
  position: relative;
  overflow: hidden;
}

.c-trim.lvh:after {
  /*-c-trim の疑似要素に指定-*/
  content: '';
  display: block;
  width: 100%;
  padding-bottom: 56.25%;
  /*-画像の縦横比を指定 初期値は75%（LDサイズ）-*/
}

.c-trim.l {
  /*-画像を内包する親要素-*/
  width: 100%;
  position: relative;
  overflow: hidden;
}

.c-trim.l:after {
  /*-c-trim の疑似要素に指定-*/
  content: '';
  display: block;
  width: 100%;
  padding-bottom: 66.6666%;
  /*-画像の縦横比を指定 初期値は75%（LDサイズ）-*/
}

.c-trim.before-after {
  /*-画像を内包する親要素-*/
  width: 100%;
  position: relative;
  overflow: hidden;
}

.c-trim.before-after:after {
  /*-c-trim の疑似要素に指定-*/
  content: '';
  display: block;
  width: 100%;
  padding-bottom: 153.6%;
  /*-画像の縦横比を指定 初期値は75%（LDサイズ）-*/
}

.c-trim__inner {
  /*-c-trim の直下にあるdivまたはaタグに指定-*/
  position: absolute !important;
  width: 100%;
  height: 100%;
}

.c-trim__after {
  /*-トリミングしたい画像や要素に指定*/
  display: block;
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

/**
 * Gap-margin
 */
/**
 * Gap-padding
 */
/* Base
-------------------------------------------------- */
/* ======================================================

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

-> scss -> base -> _color.scss

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

====================================================== */
/* ==================================================
   Color Stylez
================================================== */
/**
 * SNS Color
 */
/* ==================================================
   Color Palette Style
================================================== */
/* ======================================================

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

-> scss -> base -> _normalize.scss

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

====================================================== */
/* 
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com 
Twitter: @rich_clark
*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video, a {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  list-style-type: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  box-sizing: border-box;
}

body {
  line-height: 1;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

/* ======================================================

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

-> scss -> base -> _base.scss

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

====================================================== */
/* ==================================================
   Base Style
   ================================================== */
html {
  font-size: 62.5%;
  /*-
  @include min-width(md){
    font-size: 71.42857142%;
  }
  -*/
}

body {
  width: 100%;
  margin: 0 auto;
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 1.6;
  font-family: "Lato", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro W3","ヒラギノ角ゴ", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", "sans-serif";
  font-weight: 500;
  font-weight: 400;
  letter-spacing: 0.09em;
  color: #040102;
}

a {
  color: #040102;
  text-decoration: none;
}

a, a:before, a:after {
  -webkit-transition: 0.16s cubic-bezier(0.17, 0.935, 0.305, 1);
  transition: 0.16s cubic-bezier(0.17, 0.935, 0.305, 1);
}

a:hover {
  text-decoration: none;
  opacity: 0.8;
  color: #040102;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Lato", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro W3","ヒラギノ角ゴ", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", "sans-serif";
  font-weight: 500;
}

p {
  font-family: "Lato", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro W3","ヒラギノ角ゴ", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", "sans-serif";
  font-weight: 500;
}

img {
  max-width: 100%;
  flex-shrink: 0;
}

.cf:after {
  content: "";
  display: block;
  clear: both;
}

/**
 * テキストの選択
 */
::selection {
  background: #dedede;
}

::-moz-selection {
  background: #dedede;
}

/**
 * Scroll Bar
 */
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar:horizontal {
  height: 12px;
}

::-webkit-scrollbar-track {
  background: #efefef;
}

::-webkit-scrollbar-corner {
  background: #efefef;
}

::-webkit-scrollbar-thumb {
  background: #efefef;
  border-radius: 12px;
}

/**
 * iOS
 */
input[type="submit"],
input[type="button"] {
  border-radius: 0;
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
}

input[type="submit"]::-webkit-search-decoration,
input[type="button"]::-webkit-search-decoration {
  display: none;
}

input[type="submit"]::focus,
input[type="button"]::focus {
  outline-offset: -2px;
}

/* Layout
-------------------------------------------------- */
/* ======================================================

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

-> scss -> layout -> _header.scss

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

====================================================== */
/* ==================================================
   Header
================================================== */
.l-header__desc {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: nowrap;
  padding: 20px 5%;
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: bold;
}

@media screen and (max-width: 991px) {
  .l-header__desc {
    flex-wrap: wrap;
    padding: 20px 80px 0 0;
    font-size: 11px;
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 767px) {
  .l-header__desc {
    width: 100%;
  }
}

.l-header__desc p {
  font-weight: bold;
  flex: 1;
}

@media screen and (max-width: 767px) {
  .l-header__desc p {
    width: 100%;
    flex: auto;
  }
}

.l-header__desc a {
  border: 1px solid #F90F04;
  border-radius: 3px;
  margin-left: 10px;
  padding: 4px 5px;
  color: #F90F04;
}

.l-header__desc a i {
  margin-right: 3px;
}

@media screen and (max-width: 767px) {
  .l-header__desc a {
    margin: 0.8em auto 0 0;
  }
}

.l-header__top {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: nowrap;
}

@media screen and (max-width: 1079px) {
  .l-header__top {
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: nowrap;
  }
}

.l-header__top__menu ul {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: nowrap;
}

.l-header__top__menu ul li {
  width: 100px;
  text-align: center;
  margin-right: 15px;
}

.l-header__top__menu ul li a {
  display: block;
  width: 100%;
  font-size: 12px;
  font-size: 1.2rem;
  letter-spacing: 0;
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  .l-header__top__right {
    width: 100%;
  }
}

.l-header__top__right__container {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: nowrap;
  padding: 10px 0;
  border-top: 1px solid #040102;
}

@media screen and (max-width: 767px) {
  .l-header__top__right__container {
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    border: none;
  }
}

.l-header__top__right__container .l-header__top__logo {
  margin-right: 20px;
}

.l-header__top__right__container .l-header__top__logo a {
  font-size: 30px;
  font-size: 3rem;
  font-weight: bold;
  letter-spacing: 0;
}

.l-header__top__right__container .l-header__top__logo a img {
  max-width: 120px;
}

.l-header__top__right__container .l-header__top__tel {
  text-align: center;
}

.l-header__top__right__container .l-header__top__tel a {
  display: block;
  position: relative;
  padding-left: 45px;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1;
  letter-spacing: 0;
}

@media screen and (max-width: 767px) {
  .l-header__top__right__container .l-header__top__tel a {
    padding-left: 0;
  }
}

.l-header__top__right__container .l-header__top__tel a span {
  font-size: 2em;
  white-space: nowrap;
}

@media screen and (max-width: 991px) {
  .l-header__global-nav {
    display: none;
  }
}

.l-header__global-nav .header-m > ul {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: nowrap;
}

.l-header__global-nav .header-m > ul li {
  width: calc(100% / 6);
  border-right: 1px solid #040102;
}

.l-header__global-nav .header-m > ul li:last-child {
  border-right: none;
}

.l-header__global-nav .header-m > ul li a {
  display: block;
  padding: 20px 0;
  text-align: center;
  background: #EEE;
  font-weight: bold;
  font-size: 20px;
  font-size: 2rem;
  letter-spacing: 0;
  line-height: 1;
}

.l-header__global-nav .header-m > ul li a img {
  display: block;
  width: 50px !important;
  height: 50px !important;
  padding: 10px;
  margin: 0 auto 14px;
  border-radius: 50%;
  background: #fff;
  line-height: 0;
}

.l-header .c-top-campaign .textwidget {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.l-header .c-top-campaign .textwidget .c-top-campaign__banner {
  width: 49%;
  margin-top: 20px;
  line-height: 0;
}

@media screen and (max-width: 479px) {
  .l-header .c-top-campaign .textwidget .c-top-campaign__banner {
    width: 100%;
  }
}

#hamburger {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: nowrap;
  width: 80px;
  height: 60px;
  position: fixed;
  right: 0;
  top: 70px;
  box-sizing: border-box;
  z-index: 10;
  margin-left: 10px;
  background-color: #73A776;
  /*-
    .p-menu-bar{
      position: relative;
      height: 36px;
    }
    span {
        width: 36px;
        height: 2px;
        background: #fff;
        position: absolute;
        left: calc( ( 60px - 36px) / 2);
        &:first-child {
            top: 10px;
        }
        &:nth-child(2) {
            top: calc(50% - 1px);
        }
        &:nth-child(3) {
            bottom: 10px;
        }
    }
    -*/
}

@media screen and (min-width: 992px) {
  #hamburger {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  #hamburger {
    top: 10px;
  }
}

#hamburger img {
  width: 28px;
}

#hamburger b {
  font-size: 12px;
  font-size: 1.2rem;
  font-weight: 600;
  color: #ffffff;
  margin-top: 0.1em;
}

#hamburger.active span:first-child {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
}

#hamburger.active span:nth-child(2) {
  opacity: 0;
}

#hamburger.active span:nth-child(3) {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
}

#overlay {
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 100;
  display: none;
}

#drawer-menu {
  width: 260px;
  height: 100vh;
  padding-bottom: 120px;
  overflow-y: scroll;
  background: #fafafa;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 200;
  transform: translateX(320px);
  transition: all 0.3s ease 0s;
}

#drawer-menu.open {
  transform: translateX(0);
}

#drawer-menu .drawer-btn {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: nowrap;
  font-size: 18px;
  font-size: 1.8rem;
  padding: 0.8em 0;
  border-radius: 4px;
  letter-spacing: 0;
  font-weight: 700;
  text-align: center;
}

#drawer-menu .drawer-btn .ja {
  display: block;
}

#drawer-menu .drawer-btn .icon {
  margin-right: 10px;
}

#drawer-menu .drawer-menu__nav {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

#drawer-menu .drawer-menu__nav li {
  border-bottom: 1px solid #ccc;
  font-family: "Lato", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro W3","ヒラギノ角ゴ", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", "sans-serif";
  font-weight: 500;
}

#drawer-menu .drawer-menu__nav li a {
  display: block;
  padding: 15px;
}

#drawer-menu .drawer-menu__nav li a .en {
  display: inline-block;
  padding-right: 1em;
}

#drawer-menu .drawer-menu__nav li a .ja {
  display: inline-block;
  font-size: 0.6em;
}

#drawer-menu .drawer-menu__nav li.bnr {
  border-bottom: none;
  margin: 20px 0 0;
  padding: 0 15px;
}

#drawer-menu .drawer-menu__nav li.bnr + .bnr {
  margin-top: 10px;
}

#drawer-menu .drawer-menu__nav li.bnr a {
  display: block;
  padding: 0;
}

#drawer-menu .drawer-menu__nav li.bnr a img {
  display: block;
}

#drawer-menu .side__content {
  padding: 88px 15px 0;
}

#drawer-menu .side__content:nth-child(n+2) {
  padding-top: 20px;
}

#drawer-menu #close {
  position: relative;
  width: 28px;
  height: 28px;
  display: block;
  margin: 10px auto 30px 10px;
}

#drawer-menu #close span {
  width: 28px;
  height: 2px;
  background: #040102;
  position: absolute;
  left: 50%;
  top: 50%;
}

#drawer-menu #close span:first-child {
  transform: translate(-50%, -50%) rotate(45deg);
}

#drawer-menu #close span:nth-child(2) {
  transform: translate(-50%, -50%) rotate(-45deg);
}

/* ======================================================

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

-> scss -> layout -> _footer.scss

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

====================================================== */
/* ==================================================
   Footer
   ================================================== */
.l-footer {
  margin-top: 6em;
  background: #E0ECE1;
  letter-spacing: 0;
}

.l-footer__container {
  width: 100%;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.l-footer__container .c-footer-left {
  width: 280px;
}

@media screen and (max-width: 991px) {
  .l-footer__container .c-footer-left {
    width: 100%;
  }
}

.l-footer__container .c-footer-left a {
  font-size: 30px;
  font-size: 3rem;
  font-weight: bold;
}

.l-footer__container .c-footer-left p {
  margin: 30px 0;
  font-size: 14px;
  font-size: 1.4rem;
}

.l-footer__container .c-footer-right {
  width: 420px;
}

@media screen and (max-width: 767px) {
  .l-footer__container .c-footer-right {
    margin-top: 30px;
  }
}

.l-footer__container .c-footer-right ul {
  height: 150px;
  width: 100%;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media screen and (max-width: 479px) {
  .l-footer__container .c-footer-right ul {
    height: 230px;
  }
}

.l-footer__container .c-footer-right ul li {
  width: 130px;
  margin-bottom: 15px;
  font-size: 13px;
  font-size: 1.3rem;
  font-weight: bold;
}

.l-footer__container .c-footer-right .p-copyright {
  font-size: 12px;
  font-size: 1.2rem;
  margin-top: 2em;
}

.l-footer__container .c-footer-map {
  width: calc(100% - 750px);
}

@media screen and (max-width: 991px) {
  .l-footer__container .c-footer-map {
    width: calc(100% - 450px);
  }
}

@media screen and (max-width: 767px) {
  .l-footer__container .c-footer-map {
    width: 100%;
  }
}

/* ======================================================

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

-> scss -> layout -> _sidebar.scss

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

====================================================== */
/* ==================================================
   Sidebar
================================================== */
.l-side {
  width: 220px;
}

@media screen and (max-width: 767px) {
  .l-side {
    width: 100%;
    margin-top: 60px;
  }
}

.l-side .c-side-eco__mark {
  margin-right: 0;
}

.l-side .widget {
  margin-top: 20px;
}

.l-side .widget .widget-ttl {
  padding: 10px;
  margin-bottom: 20px;
  font-size: 15px;
  font-size: 1.5rem;
  letter-spacing: 0;
  line-height: 1.35;
  font-weight: bold;
  background: #F3CB01;
}

.l-side .widget ul li {
  margin-bottom: 5px;
  position: relative;
  border: 1px solid #dedede;
  border-bottom: 1px solid #F3CB01;
}

.l-side .widget ul li a {
  padding: 0.5em 0.5em 0.5em 4em;
  position: relative;
  display: block;
  height: 100%;
  font-size: 13px;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0;
}

.l-side .widget ul li a img {
  position: absolute;
  left: 0.5em;
  top: 50%;
  transform: translateY(-50%);
  z-index: 20;
  width: 2.8em;
  height: 2.8em;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #dedede;
}

.l-side .c-side-banner {
  width: 100%;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.l-side .c-side-banner li {
  margin-top: 20px;
}

/* ======================================================

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

-> scss -> layout -> _common.scss

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

====================================================== */
/* ==================================================
   Common Content
   ================================================== */
#wrap {
  position: relative;
  overflow: hidden;
}

#contents {
  position: relative;
}

.inner {
  width: 100%;
  max-width: calc( 1080px + 5% * 2);
  box-sizing: border-box;
  padding-left: 5%;
  padding-right: 5%;
  margin-left: auto;
  margin-right: auto;
}

.section, .l-footer, .section--top {
  padding: 120px 0;
}

@media screen and (max-width: 991px) {
  .section, .l-footer, .section--top {
    padding: 60px 0;
  }
}

.section--top {
  padding-top: 0;
}

.c-block {
  display: inline-block;
}

/* Object
-------------------------------------------------- */
/**
 * Component
 */
/* ======================================================

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

-> scss -> module -> _headline.scss

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

====================================================== */
/* ==================================================
   Headline Style
   ================================================== */
h1 {
  font-size: 3.2rem;
  margin-bottom: 0.95em;
}

h2 {
  font-size: 2.8rem;
  margin-bottom: 0.95em;
}

h3 {
  font-size: 2.4rem;
  margin-bottom: 0.95em;
}

h4 {
  font-size: 2rem;
  margin-bottom: 0.95em;
}

h5 {
  font-size: 1.6rem;
  margin-bottom: 0.95em;
}

h6 {
  font-size: 1.2rem;
  margin-bottom: 0.95em;
}

.heading, .heading__title {
  letter-spacing: 0.06em;
  font-weight: 700;
  color: black;
}

.heading__container {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.heading__title {
  width: auto;
  min-width: 360px;
  max-width: calc( 100% - 170px - 20px);
  font-size: 20px;
  font-size: 2rem;
  padding: 0 0 10px 40px;
  background-image: url(../images/common/icon-tri-red.svg);
  background-repeat: no-repeat;
  background-position: left bottom 20px;
  background-size: 30px auto;
  border-bottom: 2px solid #707070;
}

@media screen and (max-width: 479px) {
  .heading__title {
    min-width: auto;
    max-width: 80%;
  }
}

.heading__btn {
  max-width: 170px;
}

/* ======================================================

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

-> scss -> module -> _button.scss

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

====================================================== */
/* ==================================================
   Button Style
================================================== */
.c-btn, .c-estimate-form .c-btn-outer input[type="submit"] {
  outline: none;
  border: none;
  display: inline-block;
  max-width: 100%;
  min-width: 280px;
  position: relative;
  text-align: center;
  color: #040102;
  background: #fff;
  padding: 0.8em;
  font-weight: 600;
  font-family: "Lato", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro W3","ヒラギノ角ゴ", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", "sans-serif";
  font-weight: 500;
  text-decoration: none;
}

@media screen and (max-width: 767px) {
  .c-btn, .c-estimate-form .c-btn-outer input[type="submit"] {
    min-width: auto;
    width: 100%;
  }
}

.c-btn:hover, .c-estimate-form .c-btn-outer input:hover[type="submit"] {
  color: #73A776;
}

.c-btn.works .c-icon, .c-estimate-form .c-btn-outer input.works[type="submit"] .c-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0.8em;
  width: 1.5em;
  fill: currentColor;
}

.c-btn.angle-right, .c-estimate-form .c-btn-outer input.angle-right[type="submit"] {
  padding-right: 2.8em;
}

.c-btn.angle-right::after, .c-estimate-form .c-btn-outer input.angle-right[type="submit"]::after {
  content: "";
  position: absolute;
  width: 0.6em;
  height: 0.6em;
  right: 1em;
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
  border-right: 2px solid #000;
  border-bottom: 2px solid #000;
}

.c-btn.angle-right:hover:after, .c-estimate-form .c-btn-outer input.angle-right[type="submit"]:hover:after {
  border-color: transparent #73A776 #73A776 transparent;
}

.c-btn.angle-left, .c-estimate-form .c-btn-outer input.angle-left[type="submit"] {
  padding-left: 2.8em;
}

.c-btn.angle-left::after, .c-estimate-form .c-btn-outer input.angle-left[type="submit"]::after {
  content: "";
  position: absolute;
  width: 0.6em;
  height: 0.6em;
  left: 1em;
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
  border-left: 2px solid #000;
  border-top: 2px solid #000;
}

.c-btn.angle-left:hover:after, .c-estimate-form .c-btn-outer input.angle-left[type="submit"]:hover:after {
  border-color: #73A776 transparent transparent #73A776;
}

.c-btn-outer {
  display: block;
  text-align: center;
  margin-top: 4em;
}

.c-btn-outer .c-btn, .c-estimate-form .c-btn-outer input[type="submit"] {
  position: relative;
  border: 3px solid;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  .c-btn-outer .c-btn, .c-estimate-form .c-btn-outer input[type="submit"] {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

/* ======================================================

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

-> scss -> module -> _entry-content.scss

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

====================================================== */
/* ==================================================
  .entry-content
  ================================================== */
.entry-content {
  /* img */
  /* clearfix */
}

.entry-content p {
  display: block;
  margin: 1em 0;
}

.entry-content strong {
  font-weight: bold;
}

.entry-content em {
  font-style: italic;
}

.entry-content blockquote {
  display: block;
  -webkit-margin-before: 1em;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 40px;
  -webkit-margin-end: 40px;
}

.entry-content .aligncenter {
  display: block !important;
  margin: 0 auto !important;
}

.entry-content .alignright {
  float: right;
}

.entry-content .alignleft {
  float: left;
}

.entry-content img[class*="wp-image-"],
.entry-content img[class*="attachment-"] {
  height: auto;
  max-width: 100%;
}

.entry-content .clearfix {
  overflow: hidden;
  zoom: 1;
}

.entry-content .clearfix:after {
  content: "";
  display: block;
  clear: both;
}

.entry-content ul:not([class]) li {
  position: relative;
  padding-left: 1em;
  margin: 1em 0;
}

.entry-content ul:not([class]) li:before {
  content: " ";
  width: 9px;
  height: 9px;
  background: #3E3E3E;
  box-shadow: 0 0 20px rgba(51, 51, 51, 0.15) inset;
  display: block;
  position: absolute;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  left: 2px;
  top: 0.5em;
}

.entry-content ul:not([class]) li ul li:before {
  width: 5px;
  height: 5px;
  top: 10px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.entry-content ul:not([class]) li ul li ul li:before {
  width: 14px;
  height: 1px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: -2px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.entry-content ol {
  counter-reset: number;
}

.entry-content ol:not([class]) li {
  list-style: none;
  position: relative;
  padding-left: 2.0em;
  margin: 10px 0;
}

.entry-content ol:not([class]) > li:before {
  counter-increment: number;
  content: counter(number);
  background: #3E3E3E;
  box-shadow: 0 0 5em rgba(51, 51, 51, 0.15) inset;
  color: #fff;
  width: 20px;
  height: 20pz;
  font-weight: bold;
  font-family: "Lato", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro W3","ヒラギノ角ゴ", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", "sans-serif";
  font-weight: 500;
  font-size: 0.9em;
  display: block;
  text-align: center;
  line-height: 20px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 3px;
}

.entry-content li ol:not([class]) li:before {
  background: #999;
  background: none;
  color: #555;
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 0.9em;
  border-radius: 50%;
  top: 3px;
}

/* entry content */
.entry-content {
  padding: 0 0 1.5em;
  overflow: hidden;
}

#post-not-found .entry-content {
  overflow: inherit;
}

.entry-content p {
  margin: 0 0 1.6em;
  line-height: 1.75;
}

.entry-content table {
  width: 100%;
  font-size: 0.92em;
  border: 1px solid #efefef;
  margin-bottom: 1.5em;
}

.entry-content table caption {
  margin: 0 0 7px;
  font-size: 0.75em;
  color: #9fa6b4;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.entry-content tr {
  border-bottom: 1px solid #efefef;
}

.entry-content td {
  padding: .8em;
  border: 1px solid #cfcfcf;
  background: #fff;
  background: rgba(255, 255, 255, 0.6);
}

.entry-content td input {
  margin: 3px 0;
}

.entry-content th {
  background-color: #eee;
  border: 1px solid #dedede;
  padding: .8em;
}

.entry-content blockquote {
  padding: 20px 3%;
  margin: 2.5em 0 3em;
  position: relative;
  opacity: .8;
  filter: alpha(opacity=80);
  -ms-filter: "alpha(opacity=90)";
  display: block;
  text-align: center;
  font-size: 1.3em;
  line-height: 1;
  background: #fff;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}

.entry-content blockquote:before {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  background: url("../img/top/blockquote-start.svg") no-repeat left top;
  background-size: contain;
}

.entry-content blockquote:after {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  margin-left: auto;
  margin-right: 0;
  background: url("../img/top/blockquote-end.svg") no-repeat left top;
  background-size: contain;
}

.entry-content blockquote h1,
.entry-content blockquote h2,
.entry-content blockquote h3,
.entry-content blockquote h4 {
  margin-top: 0 !important;
}

.entry-content dt {
  font-weight: bold;
  margin-bottom: 2%;
}

.entry-content dd {
  margin-left: 0;
  font-size: .95em;
  margin-bottom: 4%;
}

.entry-content img {
  max-width: 100%;
  height: auto;
}

.entry-content .blocks-gallery-item img {
  margin-bottom: 0;
}

.entry-content .size-auto,
.entry-content .size-full,
.entry-content .size-large,
.entry-content .size-medium,
.entry-content .size-thumbnail {
  max-width: 100%;
  height: auto;
}

.entry-content pre {
  background: #666;
  background: rgba(0, 0, 0, 0.54);
  color: #f8f9fa;
  font-size: 0.88em;
  padding: 1.2em 1.7em;
  margin: 0.2em 0 2.2em;
  border-radius: 5px;
}

.entry-content .wp-block-image figcaption {
  display: block;
  width: 100%;
  margin-top: .5em !important;
  color: #555d66 !important;
  text-align: center !important;
  font-size: 13px !important;
}

.wp-caption {
  max-width: 100%;
  background: #fff;
  padding: 5px;
  margin-bottom: 1.8em;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 3px;
}

.wp-caption img {
  max-width: 100%;
  margin-bottom: 0;
  width: 100%;
}

.wp-caption p.wp-caption-text {
  font-size: 0.75em;
  margin: 4px 0;
  text-align: center;
}

iframe {
  max-width: 100%;
}

iframe.wp-embedded-content {
  width: 100%;
}

.entry-content h1 a,
.entry-content .h1 a,
.entry-content h2 a,
.entry-content .h2 a,
.entry-content h3 a,
.entry-content .h3 a,
.entry-content h4 a,
.entry-content .h4 a,
.entry-content h5 a,
.entry-content .h5 a {
  text-decoration: none;
}

.entry-content h1,
.entry-content h2,
.entry-content h3,
.entry-content h4,
.entry-content h5,
.entry-content h6 {
  font-family: "Lato", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro W3","ヒラギノ角ゴ", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", "sans-serif";
  font-weight: 500;
}

.entry-content h1,
.entry-content .h1 {
  font-size: 1.5em;
  font-weight: bold;
  line-height: 1.333em;
}

.entry-content h2,
.entry-content .h2 {
  font-size: 1.5em;
  line-height: 1.4em;
  font-weight: bold;
  margin-bottom: 0.375em;
}

.entry-content h3,
.entry-content .h3 {
  font-size: 1.3em;
  font-weight: bold;
}

.entry-content h4,
.entry-content .h4 {
  font-size: 1.2em;
  font-weight: 700;
}

.entry-content h5,
.entry-content .h5 {
  font-size: 1em;
  font-weight: 700;
  line-height: 2em;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.entry-content h2 {
  position: relative;
  border: none;
  font-size: 1.25em;
  padding: 1em 1.1em;
  margin-top: 2.1em;
  margin-bottom: 1em;
  background: #73A776;
  color: #fff;
}

.entry-content h3 {
  position: relative;
  border: none;
  font-size: 1.25em;
  padding: 0.7em 0.9em;
  margin-bottom: 1em;
  background: rgba(222, 222, 222, 0.4);
  color: #040102;
  margin-top: 1.8em;
}

.entry-content h4 {
  margin-top: 1.5em;
  margin-bottom: 1em;
}

.entry-content h5 {
  margin-top: 1.5em;
  margin-bottom: 1em;
}

/* ======================================================

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

-> scss -> module -> _breadcrumb.scss

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

====================================================== */
/* ==================================================
   Bread Crumb
   ================================================== */
.c-breadcrumb {
  padding: 20px 0;
}

.c-breadcrumb ul li {
  font-size: 13px;
  font-size: 1.3rem;
  display: inline;
}

.c-breadcrumb ul li a:hover {
  text-decoration: none;
}

.c-breadcrumb ul li i {
  margin-left: 10px;
}

/**
 * Utility
 */
/* ======================================================

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

-> scss -> module -> _c-flexbox.scss

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

====================================================== */
/* ==================================================
  flexbox Style
================================================== */
.c-flex {
  display: -webkit-flex;
  display: flex;
}

.c-flex.c-wrap--wrap {
  flex-wrap: wrap;
}

.c-flex.c-wrap--nowrap {
  flex-wrap: nowrap;
}

.c-flex.c-justify--between {
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.c-flex.c-justify--start {
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}

.c-flex.c-justify--center {
  -webkit-justify-content: center;
  justify-content: center;
}

.c-flex.c-justify--end {
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

.c-flex.c-align--start {
  align-items: flex-start;
}

.c-flex.c-align--center {
  align-items: center;
}

.c-flex.c-align--end {
  align-items: flex-end;
}

.c-flex.c-direction--row {
  flex-direction: row;
}

.c-flex.c-direction--row-r {
  flex-direction: row-reverse;
}

.c-flex.c-direction--col {
  flex-direction: column;
}

.c-flex.c-direction--col-r {
  flex-direction: column-reverse;
}

.c-flex .c-basis--auto {
  flex-basis: auto;
}

.c-flex .c-self--left {
  margin-left: 0;
  margin-right: auto;
}

.c-flex .c-self--right {
  margin-right: 0;
  margin-left: auto;
}

@media screen and (max-width: 1199px) {
  .flex-min--xlg {
    display: block !important;
  }
}

@media screen and (min-width: 1200px) {
  .flex-max--xlg {
    display: block !important;
  }
}

@media screen and (max-width: 1079px) {
  .flex-min--lg {
    display: block !important;
  }
}

@media screen and (min-width: 1080px) {
  .flex-max--lg {
    display: block !important;
  }
}

@media screen and (max-width: 991px) {
  .flex-min--md {
    display: block !important;
  }
}

@media screen and (min-width: 992px) {
  .flex-max--md {
    display: block !important;
  }
}

@media screen and (max-width: 767px) {
  .flex-min--sm {
    display: block !important;
  }
}

@media screen and (min-width: 768px) {
  .flex-max--sm {
    display: block !important;
  }
}

@media screen and (max-width: 479px) {
  .flex-min--xs {
    display: block !important;
  }
}

@media screen and (min-width: 480px) {
  .flex-max--xs {
    display: block !important;
  }
}

/* ======================================================

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

-> scss -> module -> _display.scss

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

====================================================== */
/* ==================================================
   Display Style
================================================== */
@media screen and (max-width: 1079px) {
  .c-min-lg {
    display: none !important;
  }
}

@media screen and (min-width: 1080px) {
  .c-max-lg {
    display: none !important;
  }
}

@media screen and (max-width: 991px) {
  .c-min-md {
    display: none !important;
  }
}

@media screen and (min-width: 992px) {
  .c-max-md {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  .c-min-sm {
    display: none !important;
  }
}

@media screen and (min-width: 768px) {
  .c-max-sm {
    display: none !important;
  }
}

@media screen and (max-width: 479px) {
  .c-min-xs {
    display: none !important;
  }
}

@media screen and (min-width: 480px) {
  .c-max-xs {
    display: none !important;
  }
}

.c-block {
  display: inline-block;
}

.c-mbs {
  margin-bottom: 60px;
}

.c-mts {
  margin-top: 60px;
}

.c-float__none {
  float: none !important;
}

/**
 * Project
 */
.c-blog__container h1 {
  font-size: 38px;
  font-size: 3.8rem;
  border: 2px dashed #73A776;
  padding: 0.7em 0 0.7em 3.6em;
  max-width: 840px;
  position: relative;
  color: #73A776;
  margin-bottom: initial;
}

@media screen and (max-width: 767px) {
  .c-blog__container h1 {
    font-size: 26px;
    font-size: 2.6rem;
  }
}

.c-blog__container h1 span {
  position: relative;
}

.c-blog__container h1 span:before {
  content: "";
  background-image: url("../images/icon/blog.png");
  position: absolute;
  left: -1.5em;
  top: 0.15em;
  width: 1em;
  height: 1em;
  display: block;
  background-size: cover;
}

.c-blog__container h1:before {
  content: "";
  left: -1.1em;
  top: -1em;
  position: absolute;
  width: 2em;
  background: #fff;
  height: 1.9em;
  border-right: 2px dashed #73A776;
  transform: rotate(45deg);
}

.c-blog__container h1:after {
  content: "";
  right: -1.1em;
  bottom: -1em;
  position: absolute;
  width: 2em;
  background: #fff;
  height: 1.9em;
  border-left: 2px dashed #73A776;
  transform: rotate(45deg);
}

.c-blog__container .p-news-info {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: bold;
  max-width: 642px;
}

@media screen and (max-width: 767px) {
  .c-blog__container .p-news-info {
    font-size: 15px;
    font-size: 1.5rem;
  }
}

.c-blog__container .c-blog__container__article {
  max-width: 760px;
  margin: 0 auto;
}

.c-blog__container .c-blog__container__article .p-news-box {
  padding: 2em 0;
  border-bottom: dashed 2px #707070;
}

@media screen and (max-width: 767px) {
  .c-blog__container .c-blog__container__article .p-news-box {
    padding: 1em 0;
  }
}

.c-blog__container .c-blog__container__article .p-news-box a {
  width: 100%;
  align-items: row;
  display: flex;
  flex-direction: wrap;
  justify-content: flex-start;
  flex-wrap: nowrap;
  color: inherit;
}

.c-blog__container .c-blog__container__article .p-news-box a .p-img-box.c-trim {
  width: 200px;
  position: relative;
  overflow: hidden;
  height: 200px;
}

@media screen and (max-width: 767px) {
  .c-blog__container .c-blog__container__article .p-news-box a .p-img-box.c-trim {
    max-width: 130px;
    height: 130px;
  }
}

.c-blog__container .c-blog__container__article .p-news-box a .p-img-box.c-trim .c-trim__img {
  display: block;
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.c-blog__container .c-blog__container__article .p-news-box a .p-news-box__text {
  max-width: 412px;
  padding-left: 2em;
  flex: 1;
}

@media screen and (max-width: 767px) {
  .c-blog__container .c-blog__container__article .p-news-box a .p-news-box__text {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.c-blog__container .c-blog__container__article .p-news-box a .p-news-box__text .p-news-box__title {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: bold;
  display: block;
  padding-bottom: 0.6em;
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  .c-blog__container .c-blog__container__article .p-news-box a .p-news-box__text .p-news-box__title {
    display: none;
  }
}

.c-blog__container .c-blog__container__article .p-news-box a .p-news-box__text .p-date {
  font-size: 15px;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5em;
}

@media screen and (max-width: 767px) {
  .c-blog__container .c-blog__container__article .p-news-box a .p-news-box__text .p-date {
    font-size: 12px;
    font-size: 1.2rem;
    display: inline;
    padding-right: 1em;
  }
}

.c-blog__container .c-blog__container__article .p-news-box a .p-news-box__text .p-news-box__category {
  font-size: 15px;
  font-size: 1.5rem;
  background-color: #fff;
  border: 1px solid #707070;
  display: inline;
  padding: 0.3em 1em;
}

@media screen and (max-width: 767px) {
  .c-blog__container .c-blog__container__article .p-news-box a .p-news-box__text .p-news-box__category {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.c-blog__container .c-blog__container__article .p-news-box a .p-news-box__text .p-news-box__desc {
  font-size: 12px;
  font-size: 1.2rem;
  padding-top: 1em;
}

.c-pagenavi {
  text-align: center;
  padding: 3em 5em 12em;
}

.p-post .p-post__title {
  border-bottom: dashed 2px #707070;
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  .p-post .p-post__title {
    font-size: 20px;
    font-size: 2rem;
  }
}

.p-post .p-post-box {
  display: flex;
  padding-bottom: 1.8em;
}

@media screen and (max-width: 991px) {
  .p-post .p-post-box {
    display: flex;
    flex-direction: column;
  }
}

.p-post .p-post-box .p-img-box.c-trim {
  width: 314px;
  position: relative;
  overflow: hidden;
  height: 200px;
}

@media screen and (max-width: 991px) {
  .p-post .p-post-box .p-img-box.c-trim {
    max-width: 314px;
    margin: 0 auto;
    order: 2;
  }
}

.p-post .p-post-box .p-img-box.c-trim .c-trim__img {
  display: block;
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.p-post .p-post-box__text {
  padding-top: 2em;
  flex: 1;
}

@media screen and (max-width: 991px) {
  .p-post .p-post-box__text {
    order: 1;
    right: 0;
    padding: initial;
    padding-bottom: 1em;
    margin-left: auto;
  }
}

.p-post .p-post-box__text .p-date {
  display: inline;
  padding: 0 2em;
  font-size: 15px;
  font-size: 1.5rem;
}

@media screen and (max-width: 991px) {
  .p-post .p-post-box__text .p-date {
    font-size: 12px;
    font-size: 1.2rem;
    padding-bottom: 1em;
  }
}

.p-post .p-post-box__text .p-post__category {
  display: inline-block;
  font-size: 16px;
  font-size: 1.6rem;
  background-color: #fff;
  border: 1px solid #707070;
  padding: 2px 16px;
}

.p-post .p-post__desc {
  font-size: 14px;
  font-size: 1.4rem;
  padding-bottom: 2em;
  font-weight: initial;
  line-height: 1.6666666;
}

.c-flow__container {
  width: 100%;
  max-width: 840px;
}

.c-flow__container h1 {
  font-size: 38px;
  font-size: 3.8rem;
  border: 2px dashed #73A776;
  padding: 0.7em 0 0.7em 3.6em;
  max-width: 840px;
  position: relative;
  color: #73A776;
  margin-bottom: initial;
}

@media screen and (max-width: 767px) {
  .c-flow__container h1 {
    font-size: 26px;
    font-size: 2.6rem;
  }
}

.c-flow__container h1 span {
  position: relative;
}

.c-flow__container h1 span:before {
  content: "";
  background-image: url("../images/icon/flow.png");
  position: absolute;
  left: -1.5em;
  top: 0.15em;
  width: 1em;
  height: 1em;
  display: block;
  background-size: cover;
}

.c-flow__container h1:before {
  content: "";
  left: -1.1em;
  top: -1em;
  position: absolute;
  width: 2em;
  background: #fff;
  height: 1.9em;
  border-right: 2px dashed #73A776;
  transform: rotate(45deg);
}

.c-flow__container h1:after {
  content: "";
  right: -1.1em;
  bottom: -1em;
  position: absolute;
  width: 2em;
  background: #fff;
  height: 1.9em;
  border-left: 2px dashed #73A776;
  transform: rotate(45deg);
}

.c-flow__container .c-flow__step {
  padding: 3.6em 0 4em 0;
}

@media screen and (max-width: 767px) {
  .c-flow__container .c-flow__step {
    font-size: 26px;
    font-size: 2.6rem;
    padding: 1em 0 1.2em 0;
  }
}

.c-flow__container .c-flow__step__text {
  font-size: 30px;
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  padding-bottom: 1em;
}

@media screen and (max-width: 767px) {
  .c-flow__container .c-flow__step__text {
    font-size: 20px;
    font-size: 2rem;
  }
}

.c-flow__list li {
  border: 1px solid #707070;
  position: relative;
  margin-bottom: 6em;
}

@media screen and (max-width: 767px) {
  .c-flow__list li {
    margin-bottom: 3em;
  }
}

.c-flow__list li .c-flow-box {
  max-width: 800px;
  padding: 2em 3em 1em 10em;
  position: relative;
}

@media screen and (max-width: 767px) {
  .c-flow__list li .c-flow-box {
    padding: 3em 1rem 0 1rem;
  }
}

.c-flow__list li .c-flow-box .c-heading-03 {
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  .c-flow__list li .c-flow-box .c-heading-03 {
    margin-bottom: 1em;
    text-align: center;
    font-size: 28px;
    font-size: 2.8rem;
  }
}

.c-flow__list li .c-flow-box p {
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .c-flow__list li .c-flow-box p {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.c-flow__list li .c-flow-box .c-tel-img-box {
  padding: 1em 0;
}

@media screen and (max-width: 767px) {
  .c-flow__list li .c-flow-box .c-tel-img-box {
    display: none;
  }
}

.c-flow__list li .c-btn-outer {
  text-align: center;
  padding-bottom: 2em;
}

@media screen and (max-width: 767px) {
  .c-flow__list li .c-btn-outer {
    padding: 1em 0 2em 0;
  }
}

.c-flow__list li .c-btn-outer .c-btn, .c-flow__list li .c-estimate-form .c-btn-outer input[type="submit"], .c-estimate-form .c-flow__list li .c-btn-outer input[type="submit"] {
  font-size: 18px;
  font-size: 1.8rem;
  color: #73A776;
  background: #fff;
  display: inline-block;
  cursor: pointer;
  position: relative;
  border: 3px solid #73A776;
  padding: 1em 4em;
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  .c-flow__list li .c-btn-outer .c-btn, .c-flow__list li .c-estimate-form .c-btn-outer input[type="submit"], .c-estimate-form .c-flow__list li .c-btn-outer input[type="submit"] {
    margin-left: 10px;
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.c-flow__list li .c-btn-outer .c-btn:after, .c-flow__list li .c-estimate-form .c-btn-outer input[type="submit"]:after, .c-estimate-form .c-flow__list li .c-btn-outer input[type="submit"]:after {
  content: "";
  background-image: url("../images/main/sign_icon_right.png");
  color: #73A776;
  position: absolute;
  right: 1em;
  top: 1.2em;
  width: 1em;
  height: 1em;
  display: block;
  background-size: cover;
}

.c-flow__list li:nth-child(n+2) .c-flow-box {
  padding: 5.5em 3em 5.5em 10em;
}

@media screen and (max-width: 767px) {
  .c-flow__list li:nth-child(n+2) .c-flow-box {
    padding: 3em 2rem 3rem 2rem;
  }
}

.c-flow__list li:nth-child(1):before {
  content: "";
  background-image: url("../images/main/flow_step1.png");
  position: absolute;
  left: 3.5em;
  top: 50%;
  width: 50px;
  height: 100px;
  display: block;
  background-size: cover;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

@media screen and (max-width: 767px) {
  .c-flow__list li:nth-child(1):before {
    top: 5em;
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.c-flow__list li:nth-child(2):before {
  content: "";
  background-image: url("../images/main/flow_step2.png");
  position: absolute;
  left: 3.5em;
  top: 50%;
  width: 50px;
  height: 100px;
  display: block;
  background-size: cover;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

@media screen and (max-width: 767px) {
  .c-flow__list li:nth-child(2):before {
    top: 5em;
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.c-flow__list li:nth-child(3):before {
  content: "";
  background-image: url("../images/main/flow_step3.png");
  position: absolute;
  left: 3.5em;
  top: 50%;
  width: 50px;
  height: 100px;
  display: block;
  background-size: cover;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

@media screen and (max-width: 767px) {
  .c-flow__list li:nth-child(3):before {
    top: 5em;
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.c-flow__list li:nth-child(4):before {
  content: "";
  background-image: url("../images/main/flow_step4.png");
  position: absolute;
  left: 3.5em;
  top: 50%;
  width: 50px;
  height: 100px;
  display: block;
  background-size: cover;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

@media screen and (max-width: 767px) {
  .c-flow__list li:nth-child(4):before {
    top: 5em;
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.c-flow__list li:nth-child(5):before {
  content: "";
  background-image: url("../images/main/flow_step5.png");
  position: absolute;
  left: 3.5em;
  top: 50%;
  width: 50px;
  height: 100px;
  display: block;
  background-size: cover;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

@media screen and (max-width: 767px) {
  .c-flow__list li:nth-child(5):before {
    top: 5em;
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.c-flow__list li:not(:last-child):after {
  content: "";
  background-image: url("../images/main/sign_icon_btm.png");
  position: absolute;
  left: 50%;
  bottom: -3.5em;
  width: 1.5em;
  height: 1.5em;
  display: block;
  background-size: cover;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
}

@media screen and (max-width: 767px) {
  .c-flow__list li:not(:last-child):after {
    bottom: -2.5em;
  }
}

.c-works .c-works__container {
  max-width: 830px;
  width: 100%;
}

.c-works .c-works__container h1 {
  font-size: 38px;
  font-size: 3.8rem;
  border: 2px dashed #73A776;
  padding: 0.7em 0 0.7em 3.6em;
  max-width: 840px;
  position: relative;
  color: #73A776;
  margin-bottom: initial;
}

@media screen and (max-width: 767px) {
  .c-works .c-works__container h1 {
    font-size: 26px;
    font-size: 2.6rem;
  }
}

.c-works .c-works__container h1 span {
  position: relative;
}

.c-works .c-works__container h1 span:before {
  content: "";
  background-image: url("../images/icon/works.png");
  position: absolute;
  left: -1.5em;
  top: 0.15em;
  width: 1em;
  height: 1em;
  display: block;
  background-size: cover;
}

.c-works .c-works__container h1:before {
  content: "";
  left: -1.1em;
  top: -1em;
  position: absolute;
  width: 2em;
  background: #fff;
  height: 1.9em;
  border-right: 2px dashed #73A776;
  transform: rotate(45deg);
}

.c-works .c-works__container h1:after {
  content: "";
  right: -1.1em;
  bottom: -1em;
  position: absolute;
  width: 2em;
  background: #fff;
  height: 1.9em;
  border-left: 2px dashed #73A776;
  transform: rotate(45deg);
}

.c-works .c-works__container .c-works__contact {
  text-align: center;
  padding: 4em 0 4.6em 0;
}

@media screen and (max-width: 767px) {
  .c-works .c-works__container .c-works__contact {
    font-size: 26px;
    font-size: 2.6rem;
    padding: 1em 0 1.2em 0;
  }
}

.c-works .c-works__container ul {
  width: 100%;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.c-works .c-works__container ul li {
  margin-right: 25px;
  width: calc( 33.3333% - 25px);
}

@media screen and (max-width: 991px) {
  .c-works .c-works__container ul li {
    width: calc( 50% - 10px);
    margin-right: 10px;
  }
}

.c-works .c-works__container ul li a {
  color: inherit;
}

.c-works .c-works__container ul li a .p-img-box.c-trim {
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 200px;
}

@media screen and (max-width: 991px) {
  .c-works .c-works__container ul li a .p-img-box.c-trim {
    max-width: 240px;
    margin: 0 auto;
  }
}

.c-works .c-works__container ul li a .p-img-box.c-trim .c-trim__img {
  display: block;
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.c-works .c-works__container ul li a .p-text-box h2 {
  font-size: 18px;
  font-size: 1.8rem;
  color: #46B5D8;
  padding: 0.75em 0;
  margin: initial;
}

@media screen and (max-width: 767px) {
  .c-works .c-works__container ul li a .p-text-box h2 {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.c-works .c-works__container ul li a .p-text-box .p-text-box__num {
  display: flex;
}

@media screen and (max-width: 767px) {
  .c-works .c-works__container ul li a .p-text-box .p-text-box__num {
    justify-content: center;
  }
}

.c-works .c-works__container ul li a .p-text-box .p-text-box__num .p-text {
  font-size: 12px;
  font-size: 1.2rem;
  font-weight: bold;
  background-color: #DEDEDE;
  margin-right: 0.8em;
  padding: 4px 8px 4px 27px;
  position: relative;
}

.c-works .c-works__container ul li a .p-text-box .p-text-box__num .p-text:before {
  content: "";
  background-image: url("../images/works/pin_icon.png");
  color: #73A776;
  position: absolute;
  left: 0.8em;
  top: 0.6em;
  width: 1em;
  height: 1em;
  display: block;
  background-size: cover;
}

.c-works .c-works__container ul li a .p-text-box .p-text-box__num .p-num {
  font-size: 14px;
  font-size: 1.4rem;
}

.c-works .c-works__container ul li:nth-child(3n) {
  margin-right: initial;
}

@media screen and (max-width: 991px) {
  .c-works .c-works__container ul li:nth-child(2n) {
    margin-right: initial;
  }
}

.c-works .c-works__container ul li:nth-child(n+4) {
  margin-top: 40px;
}

.c-works .c-works__container ul li:nth-child(n+4) {
  margin-top: 40px;
}

@media screen and (max-width: 991px) {
  .c-works .c-works__container ul li:nth-child(n+3) {
    margin-top: 40px;
  }
}

.c-sitemap .inner {
  display: inline;
}

.c-sitemap .inner h1 {
  text-align: center;
  font-size: 30px;
  font-size: 3rem;
  padding-bottom: 1.8em;
  margin-bottom: initial;
}

.c-sitemap .inner h1 span {
  background: linear-gradient(transparent 70%, #73A776 70%);
  font-weight: bold;
}

.c-sitemap .inner .c-sitemap__container {
  width: 100%;
  max-width: calc( 1080px + 5% * 2);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 5%;
  padding-right: 5%;
  margin-left: auto;
  margin-right: auto;
}

.c-sitemap .inner .c-sitemap__container .c-sitemap__container__inner {
  display: flex;
  justify-content: center;
  max-width: 540px;
  margin: 0 auto;
}

.c-sitemap .inner .c-sitemap__container .c-sitemap__container__inner .c-sitemap__block {
  width: 50%;
}

.c-sitemap .inner .c-sitemap__container .c-sitemap__container__inner .c-sitemap__block ul li {
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: bold;
}

.c-sitemap .inner .c-sitemap__container .c-sitemap__container__inner .c-sitemap__block ul li:nth-child(n+2) {
  padding-top: 0.7em;
}

.c-sitemap .inner .c-sitemap__container .c-sitemap__container__inner .c-sitemap__block ul li a {
  color: inherit;
}

.c-privacy__container h1 {
  font-size: 20px;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 3em;
}

.c-privacy__container h2 {
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: bold;
  margin-top: 1em;
  margin-bottom: initial;
}

.c-privacy__container p {
  font-size: 14px;
  font-size: 1.4rem;
}

.c-voice .c-voice__container h1 {
  font-size: 38px;
  font-size: 3.8rem;
  border: 2px dashed #73A776;
  padding: 0.7em 0 0.7em 3.6em;
  max-width: 840px;
  position: relative;
  color: #73A776;
  margin-bottom: initial;
}

@media screen and (max-width: 767px) {
  .c-voice .c-voice__container h1 {
    font-size: 26px;
    font-size: 2.6rem;
  }
}

.c-voice .c-voice__container h1 span {
  position: relative;
}

.c-voice .c-voice__container h1 span:before {
  content: "";
  background-image: url("../images/icon/works.png");
  position: absolute;
  left: -1.5em;
  top: 0.15em;
  width: 1em;
  height: 1em;
  display: block;
  background-size: cover;
}

.c-voice .c-voice__container h1:before {
  content: "";
  left: -1.1em;
  top: -1em;
  position: absolute;
  width: 2em;
  background: #fff;
  height: 1.9em;
  border-right: 2px dashed #73A776;
  transform: rotate(45deg);
}

.c-voice .c-voice__container h1:after {
  content: "";
  right: -1.1em;
  bottom: -1em;
  position: absolute;
  width: 2em;
  background: #fff;
  height: 1.9em;
  border-left: 2px dashed #73A776;
  transform: rotate(45deg);
}

.c-voice .c-voice__container .c-works__contact {
  text-align: center;
  padding: 4em 0 4.6em 0;
}

@media screen and (max-width: 767px) {
  .c-voice .c-voice__container .c-works__contact {
    font-size: 26px;
    font-size: 2.6rem;
    padding: 1em 0 1.2em 0;
  }
}

.c-voice .c-voice__container .c-voice__lists ul {
  padding-top: 3em;
}

.c-voice .c-voice__container .c-voice__lists ul li {
  max-width: 620px;
  margin-left: auto;
  text-align: center;
}

.c-voice .c-voice__container .c-voice__lists ul li .p-voice-text {
  font-size: 18px;
  font-size: 1.8rem;
  position: relative;
  display: inline-block;
  padding: 1em;
  min-width: 120px;
  max-width: 100%;
  border: solid 3px #555;
  box-sizing: border-box;
}

.c-voice .c-voice__container .c-voice__lists ul li .p-voice-text:before {
  content: "";
  position: absolute;
  bottom: -24px;
  left: 50%;
  margin-left: -15px;
  border: 12px solid transparent;
  border-top: 12px solid #FFF;
  z-index: 2;
}

.c-voice .c-voice__container .c-voice__lists ul li .p-voice-text:after {
  content: "";
  position: absolute;
  bottom: -30px;
  left: 50%;
  margin-left: -17px;
  border: 14px solid transparent;
  border-top: 14px solid #555;
  z-index: 1;
}

.c-voice .c-voice__container .c-voice__lists ul li .p-voice-link {
  font-size: 18px;
  font-size: 1.8rem;
  border-bottom: 1px solid #F90F04;
  color: #F90F04;
  display: inline-block;
  padding-top: 2em;
  font-weight: bold;
}

.c-voice .c-voice__container .c-voice__lists ul li:not(:last-child) {
  padding-bottom: 2em;
}

.c-voice .c-voice__container .c-voice__contact {
  max-width: 640px;
  margin-left: auto;
  margin-top: 3em;
  padding: 2em 1.8em 2em 1.1em;
  border: solid 1px #030303;
}

.c-voice .c-voice__container .c-voice__contact h2 {
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: bold;
}

.c-voice .c-voice__container .c-voice__contact .p-contact__text-top {
  font-size: 18px;
  font-size: 1.8rem;
  padding-top: 2em;
}

.c-voice .c-voice__container .c-voice__contact .c-tel-img-box {
  padding-top: 2em;
}

.c-voice .c-voice__container .c-voice__contact .c-btn-outer {
  text-align: center;
  padding-top: 2em;
}

.c-voice .c-voice__container .c-voice__contact .p-contact__text-btm {
  padding-top: 2em;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: bold;
}

.c-voice .c-voice__container-box {
  margin-top: 5em;
}

.c-voice .c-voice__container-box .c-voice__menu {
  margin-top: 1em;
}

/* ==================================================
   Pages
================================================== */
#fixed_btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

@media screen and (max-width: 767px) {
  #fixed_btn {
    bottom: 10px;
    right: 10px;
  }
}

#fixed_btn a {
  position: relative;
  display: block;
  transform: rotate(-36deg);
  transform-origin: left bottom;
}

@media screen and (max-width: 767px) {
  #fixed_btn a {
    width: 17vw;
    max-width: 80px;
    transform: rotate(-20deg);
  }
}

#fixed_btn a img.package {
  position: relative;
  z-index: 2;
}

#fixed_btn a img.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transition: 0.4s cubic-bezier(0.17, 0.935, 0.305, 1);
  transition: 0.4s cubic-bezier(0.17, 0.935, 0.305, 1);
  max-width: 80%;
}

#fixed_btn a:hover {
  opacity: 1;
}

#fixed_btn a:hover img.icon {
  transform: translate(-50%, -180px);
}

@media screen and (max-width: 767px) {
  #fixed_btn a:hover img.icon {
    transform: translate(-50%, -36vw);
  }
}

.top-slider {
  margin: 20px 0 60px;
}

@media screen and (max-width: 767px) {
  .top-slider {
    margin: 20px 0;
  }
}

.swiper-container {
  position: relative;
  padding-bottom: 30px;
}

.swiper-container .swiper-pagination .swiper-pagination-bullet {
  width: 60px;
  height: 6px;
  border-radius: 0;
}

@media screen and (max-width: 479px) {
  .swiper-container .swiper-pagination .swiper-pagination-bullet {
    width: 15px;
  }
}

.swiper-container .swiper-pagination .swiper-pagination-bullet-active {
  background: #4DC269;
}

.c-eco {
  margin-top: 60px;
}

.c-eco h2 {
  margin-bottom: 40px;
  text-align: center;
  font-weight: bold;
  font-size: 26px;
  font-size: 2.6rem;
  letter-spacing: 0;
}

.c-eco h2 small {
  font-size: 75%;
  font-style: normal;
}

@media screen and (max-width: 479px) {
  .c-eco__container {
    flex-wrap: wrap;
  }
}

.c-eco__container .c-eco__item {
  margin: 10px;
}

@media screen and (max-width: 479px) {
  .c-eco__container .c-eco__item {
    width: 100%;
  }
}

.c-eco__container .c-eco__item img {
  border: 1px solid #dedede;
}

.c-eco__msg {
  max-width: 880px;
  padding: 20px 0;
  margin: 60px auto;
  border: 1px solid #dedede;
}

@media screen and (max-width: 479px) {
  .c-eco__msg {
    flex-wrap: wrap;
  }
}

.c-eco__msg > p {
  flex: 1;
  padding: 0.4em 1em;
  font-weight: bold;
  font-size: 18px;
  font-size: 1.8rem;
  letter-spacing: 0;
}

@media screen and (max-width: 767px) {
  .c-eco__msg > p {
    padding: 10px;
    font-size: 14px;
    font-size: 1.4rem;
  }
  .c-eco__msg > p br {
    display: none;
  }
}

@media screen and (max-width: 479px) {
  .c-eco__msg > p {
    flex: none;
    width: 100%;
  }
}

.c-eco__mark {
  padding: 0.6em 0.8em;
  background: #efefef;
}

.c-eco__mark figure {
  line-height: 0;
  margin-right: 5px;
}

.c-eco__mark p {
  text-align: center;
  font-weight: bold;
  font-size: 15px;
  font-size: 1.5rem;
  letter-spacing: 0;
}

.l-main__container {
  width: 100%;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  .l-main__container {
    flex-direction: column-reverse;
  }
}

.l-main {
  width: calc(100% - 250px);
}

@media screen and (max-width: 767px) {
  .l-main {
    width: 100%;
  }
}

.c-product__header {
  margin-bottom: 1.5em;
  font-size: 24px;
  font-size: 2.4rem;
}

.c-product__header h2 {
  position: relative;
  padding: 1.5em 1em 1.5em 2em;
  border-right: 2px dashed #dedede;
  font-weight: bold;
  letter-spacing: 0;
  flex-shrink: 0;
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  .c-product__header h2 {
    font-size: 20px;
    font-size: 2rem;
  }
}

.c-product__header h2::before {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  content: '';
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background: url(../images/common/icon1.jpg) no-repeat center/contain;
}

.c-product__header p {
  padding-left: 1.5em;
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  .c-product__header p {
    font-size: 13px;
    font-size: 1.3rem;
  }
  .c-product__header p br {
    display: none;
  }
}

.c-product__container ul {
  width: 100%;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.c-product__container ul li {
  max-width: 49%;
  width: calc( 400px - 15px);
  margin-bottom: 50px;
}

@media screen and (max-width: 479px) {
  .c-product__container ul li {
    width: 100%;
  }
}

.c-product__container ul li a {
  display: block;
}

.c-product__container ul li a > figure {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  line-height: 0;
}

.c-product__container ul li a > figure img {
  width: 100%;
  height: auto;
}

.c-product .p-product-txt p {
  font-size: 13px;
  font-size: 1.3rem;
  letter-spacing: 0;
}

.c-product .p-product-txt .p-product-meta {
  margin-bottom: 20px;
}

.c-product .p-product-txt .p-product-meta .p-product-eco__mark {
  width: 96px;
  padding: 5px;
  margin-right: 12px;
}

.c-product .p-product-txt .p-product-meta .p-product-eco__mark figure {
  width: 25px;
  margin-right: 0;
}

.c-product .p-product-txt .p-product-meta .p-product-eco__mark p {
  font-size: 12px;
  font-size: 1.2rem;
}

.c-product .p-product-txt .p-product-meta__data {
  flex: 1;
}

.c-product .p-product-txt .p-product-meta__data .p-product-meta__tag {
  width: 96px;
  margin-bottom: 7px;
  padding: 3px;
  text-align: center;
  border-radius: 2px;
  font-size: 12px;
  font-size: 1.2rem;
  font-weight: bold;
  background: #F3CB01;
}

.c-other-works {
  letter-spacing: 0;
}

.c-other-works .c-works__contact {
  text-align: center;
  padding: 0 0 2em 0;
}

@media screen and (max-width: 479px) {
  .c-other-works .c-works__contact {
    padding-bottom: 0;
  }
}

.c-other-works .c-works__container {
  max-width: 840px;
  margin-left: auto;
  margin-right: auto;
}

.c-other-works .c-works__container .c-works-list {
  width: 100%;
  width: 100%;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.c-other-works .c-works__container .c-works-list .c-works-box {
  margin-right: 25px;
  width: calc( 33.3333% - 25px);
}

@media screen and (max-width: 991px) {
  .c-other-works .c-works__container .c-works-list .c-works-box {
    width: calc( 50% - 10px);
    margin-right: 10px;
  }
}

@media screen and (max-width: 479px) {
  .c-other-works .c-works__container .c-works-list .c-works-box {
    width: 100%;
    margin-right: 0;
    margin-top: 40px;
  }
}

.c-other-works .c-works__container .c-works-list .c-works-box a {
  color: inherit;
}

.c-other-works .c-works__container .c-works-list .c-works-box a .p-img-box.c-trim {
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 200px;
}

@media screen and (max-width: 991px) {
  .c-other-works .c-works__container .c-works-list .c-works-box a .p-img-box.c-trim {
    max-width: 240px;
    margin: 0 auto;
  }
}

.c-other-works .c-works__container .c-works-list .c-works-box a .p-img-box.c-trim img {
  display: block;
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.c-other-works .c-works__container .c-works-list .c-works-box a .p-text-box h2 {
  font-size: 18px;
  font-size: 1.8rem;
  color: #46B5D8;
  padding: 0.75em 0;
  margin: initial;
}

.c-other-works .c-works__container .c-works-list .c-works-box a .p-text-box .p-text-box__num {
  display: flex;
}

.c-other-works .c-works__container .c-works-list .c-works-box a .p-text-box .p-text-box__num .p-text {
  font-size: 12px;
  font-size: 1.2rem;
  font-weight: bold;
  background-color: #DEDEDE;
  margin-right: 0.8em;
  padding: 4px 8px 4px 27px;
  position: relative;
}

.c-other-works .c-works__container .c-works-list .c-works-box a .p-text-box .p-text-box__num .p-text:before {
  content: "";
  background-image: url("../images/works/pin_icon.png");
  color: #73A776;
  position: absolute;
  left: 0.8em;
  top: 0.6em;
  width: 1em;
  height: 1em;
  display: block;
  background-size: cover;
}

.c-other-works .c-works__container .c-works-list .c-works-box a .p-text-box .p-text-box__num .p-num {
  font-size: 14px;
  font-size: 1.4rem;
}

.c-other-works .c-works__container .c-works-list li:nth-child(3n) {
  margin-right: initial;
}

@media screen and (max-width: 991px) {
  .c-other-works .c-works__container .c-works-list li:nth-child(2n) {
    margin-right: initial;
  }
}

.c-other-works .c-works__container .c-works-list li:nth-child(n+4) {
  margin-top: 40px;
}

.c-other-works .c-works__container .c-works-list li:nth-child(n+4) {
  margin-top: 40px;
}

@media screen and (max-width: 991px) {
  .c-other-works .c-works__container .c-works-list li:nth-child(n+3) {
    margin-top: 40px;
  }
}

.c-product-explain {
  max-width: 780px;
  margin-left: auto;
  margin-right: auto;
}

.c-product-explain__item {
  margin-bottom: 50px;
}

.c-product-explain__item h2 {
  max-width: 210px;
  margin-bottom: 20px;
  padding: 8px 10px;
  background: #F3CB01;
  font-size: 15px;
  font-size: 1.5rem;
  letter-spacing: 0;
  font-weight: bold;
}

.c-product-explain__item ul {
  width: 100%;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.c-product-explain__item ul li {
  letter-spacing: 0;
}

.c-product-explain__item ul li .p-product-explain__img {
  text-align: center;
}

.c-product-explain__item ul li .p-product-explain__img figcaption {
  display: inline-block;
  padding: 0 10px;
  border-radius: 3px;
  background: #000;
  color: #fff;
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: bold;
}

.c-product-explain__item ul li .p-product-explain__img figcaption span {
  font-size: 12px;
  font-size: 1.2rem;
  font-weight: normal;
}

.c-product-explain__item ul li .p-product-explain__cont {
  font-size: 14px;
  font-size: 1.4rem;
}

.c-product-explain__item ul li .p-product-explain__cont h3 {
  margin-bottom: 10px;
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: bold;
}

.c-product-explain__item ul li .p-product-explain__cont p {
  margin-bottom: 20px;
}

.c-product-explain__item .c-product__material li {
  width: 100%;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 49%;
}

@media screen and (max-width: 767px) {
  .c-product-explain__item .c-product__material li {
    width: 100%;
    margin-bottom: 20px;
  }
}

.c-product-explain__item .c-product__material li .p-product-explain__img {
  width: 55%;
}

@media screen and (max-width: 767px) {
  .c-product-explain__item .c-product__material li .p-product-explain__img {
    width: 45%;
  }
}

@media screen and (max-width: 479px) {
  .c-product-explain__item .c-product__material li .p-product-explain__img {
    width: 100%;
  }
}

.c-product-explain__item .c-product__material li .p-product-explain__img figcaption {
  transform: translateY(20%);
}

.c-product-explain__item .c-product__material li .p-product-explain__cont {
  width: 44%;
}

@media screen and (max-width: 767px) {
  .c-product-explain__item .c-product__material li .p-product-explain__cont {
    width: 53%;
  }
}

@media screen and (max-width: 479px) {
  .c-product-explain__item .c-product__material li .p-product-explain__cont {
    width: 100%;
  }
}

.c-product-explain__item .c-product__material li .p-product-explain__cont.w-100 {
  width: 100%;
  max-width: 350px;
  margin-top: 10px;
}

.c-product-explain__item .c-product__size {
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
}

.c-product-explain__item .c-product__size li {
  width: 25%;
  margin-bottom: 30px;
}

@media screen and (max-width: 479px) {
  .c-product-explain__item .c-product__size li {
    width: 50%;
    margin-bottom: 0;
  }
}

.c-product-explain__item .c-product__size li .p-product-explain__img figcaption {
  transform: translateY(20%);
}

.c-product-explain__item .c-product__color {
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
}

.c-product-explain__item .c-product__color li {
  width: 32%;
  margin-bottom: 30px;
}

@media screen and (max-width: 479px) {
  .c-product-explain__item .c-product__color li {
    width: 100%;
    margin-bottom: 0;
  }
}

.c-product-explain__item .c-product__color li .p-product-explain__img figcaption {
  margin: 10px 0;
}

.c-works-slider {
  padding: 80px 0;
  background: #D2D2D2;
}

@media screen and (max-width: 479px) {
  .c-works-slider {
    padding: 50px 0;
  }
}

.c-works-slider .swiper-slide {
  background: #fff;
  margin-top: 0 !important;
}

.c-works-slider .swiper-slide .p-text-box {
  padding: 0 10px 10px;
}

.c-works-slider .swiper-pagination {
  bottom: 30px;
}

@media screen and (max-width: 479px) {
  .c-works-slider .swiper-pagination {
    bottom: 15px;
  }
}

.c-sub-contact {
  max-width: 600px;
  margin: 100px auto;
  font-size: 18px;
  font-size: 1.8rem;
  letter-spacing: 0;
}

@media screen and (max-width: 479px) {
  .c-sub-contact {
    margin: 60px auto;
  }
}

.c-sub-contact__cont {
  margin: 1.6em auto;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.c-sub-contact__cont > p {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: bold;
}

.c-sub-contact__cont .c-sub-contact__tel {
  text-align: center;
  width: 100%;
  max-width: 376px;
}

.c-sub-contact__cont .c-sub-contact__tel a {
  display: block;
  position: relative;
  font-size: 22px;
  font-size: 2.2rem;
  line-height: 1;
  letter-spacing: 0;
}

@media screen and (max-width: 767px) {
  .c-sub-contact__cont .c-sub-contact__tel a {
    padding-left: 0;
    display: inline-block;
  }
}

.c-sub-contact__cont .c-sub-contact__tel a img {
  width: 100%;
}

.c-sub-contact__cont .c-sub-contact__tel a span {
  font-size: 42px;
  font-size: 4.2rem;
}

@media screen and (max-width: 479px) {
  .c-sub-contact__cont .c-sub-contact__tel a span {
    font-size: 36px;
    font-size: 3.6rem;
  }
}

.c-sub-contact .c-btn-outer {
  margin-top: 0;
}

.c-product__detail__container {
  width: 100%;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-left: 30px;
}

@media screen and (max-width: 991px) {
  .c-product__detail__container {
    padding-left: 0;
  }
}

.c-product__detail__container .c-product__thumbnail {
  width: 45%;
}

@media screen and (max-width: 479px) {
  .c-product__detail__container .c-product__thumbnail {
    width: 100%;
  }
}

.c-product__detail__container .c-product__thumbnail img {
  height: auto;
}

.c-product__detail__container .c-product__detail__cont {
  width: calc(55% - 30px);
}

@media screen and (max-width: 991px) {
  .c-product__detail__container .c-product__detail__cont {
    width: calc(55% - 15px);
  }
}

@media screen and (max-width: 479px) {
  .c-product__detail__container .c-product__detail__cont {
    width: 100%;
  }
}

.c-product__detail__container .c-product__detail__cont .c-product__images {
  width: 100%;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 300px;
}

.c-product__detail__container .c-product__detail__cont .c-product__images figure {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: nowrap;
  width: 80px;
  height: 80px;
  margin: 0 20px 20px 0;
  background: #efefef;
}

.c-product__detail__info {
  width: 100%;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-left: 30px;
  letter-spacing: 0;
}

@media screen and (max-width: 991px) {
  .c-product__detail__info {
    padding-left: 0;
  }
}

.c-product__detail__info .p-product__detail__tag {
  display: inline-block;
  min-width: 100px;
  margin-bottom: 15px;
  padding: 4px 13px;
  text-align: center;
  border: 1px solid #707070;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1;
}

.c-product__detail__info .c-product__detail__color {
  width: 50%;
  margin-top: 60px;
  padding-left: 20px;
}

@media screen and (max-width: 479px) {
  .c-product__detail__info .c-product__detail__color {
    width: 100%;
    padding-left: 0;
  }
}

.c-product__detail__info .c-product__detail__color .p-product__color__group {
  width: 100%;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 310px;
}

.c-product__detail__info .c-product__detail__color .p-product__color__group .p-product__color__sample {
  width: 35px;
  margin: 5px 8px;
  padding-top: 40px;
  position: relative;
}

.c-product__detail__info .c-product__detail__color .p-product__color__group .p-product__color__sample::before {
  content: '';
  width: 35px;
  height: 35px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
}

.c-product__detail__info .c-product__detail__color .p-product__color__group .p-product__color__sample p {
  text-align: center;
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: -0.05em;
}

.c-product__detail__info .c-product__detail__color .p-product__color__group .color-sample1::before {
  background: #F51900;
}

.c-product__detail__info .c-product__detail__color .p-product__color__group .color-sample2::before {
  background: #1229C4;
}

.c-product__detail__info .c-product__detail__color .p-product__color__group .color-sample3::before {
  background: #FA5B00;
}

.c-product__detail__info .c-product__detail__color .p-product__color__group .color-sample4::before {
  background: #F0D202;
}

.c-product__detail__info .c-product__detail__color .p-product__color__group .color-sample5::before {
  background: #2A9B49;
}

.c-product__detail__info .c-product__detail__color .p-product__color__group .color-sample6::before {
  background: #FA719B;
}

.c-product__detail__info .c-product__detail__color .p-product__color__group .color-sample7::before {
  background: #3F302D;
}

.c-product__detail__info .c-product__detail__color .p-product__color__group .color-sample8::before {
  background: #1F4C45;
}

.c-product__detail__info .c-product__detail__color .p-product__color__group .color-sample9::before {
  background: #F1EFA5;
}

.c-product__detail__info .c-product__detail__color .p-product__color__group .color-sample10::before {
  background: #FFFFFF;
  border: 1px solid #ddd;
}

.c-product__detail__info .c-product__detail__color .p-product__color__group .color-sample11::before {
  background: #000000;
}

.c-product__detail__info .c-product__detail__color .p-product__color__group .color-sample12::before {
  background: #B2B2B2;
}

.c-product__detail__info .c-product__detail__color .p-product__color__txt {
  margin: 10px 0;
  font-size: 14px;
  font-size: 1.4rem;
}

.c-product__detail__info .c-product__detail__color a {
  font-size: 15px;
  font-size: 1.5rem;
  text-decoration: underline;
  color: #F90F04;
}

.c-product__detail__info .c-product__detail__properties {
  width: 50%;
  margin-top: 60px;
  padding-left: 20px;
}

@media screen and (max-width: 479px) {
  .c-product__detail__info .c-product__detail__properties {
    width: 100%;
    padding-left: 0;
  }
}

.c-product__detail__info .c-product__detail__properties .p-product__detail__property-item {
  display: inline-block;
  min-width: 42px;
  margin: 0 13px 15px 0;
  padding: 5px 8px;
  text-align: center;
  border-radius: 3px;
  background: #EEE;
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1;
}

.c-product__detail__info .c-product__detail__properties .p-product__detail__property-item span {
  font-size: 12px;
  font-size: 1.2rem;
  font-weight: normal;
}

.c-product__detail__info .c-product__detail__price {
  width: 100%;
  margin: 60px 0;
  padding: 0 15px;
}

@media screen and (max-width: 991px) {
  .c-product__detail__info .c-product__detail__price {
    padding: 0;
  }
}

.c-product__detail__info .c-product__detail__price .p-product__detail__tag {
  margin-left: 5px;
}

.c-product__detail__info .c-product__detail__price table {
  width: 100%;
  margin-bottom: 30px;
  text-align: center;
  letter-spacing: -0.1em;
  font-size: 16px;
  font-size: 1.6rem;
}

.c-product__detail__info .c-product__detail__price table th,
.c-product__detail__info .c-product__detail__price table td {
  width: 25%;
  padding: 2px;
  border: 1px solid #fff;
}

.c-product__detail__info .c-product__detail__price table thead {
  font-weight: bold;
}

.c-product__detail__info .c-product__detail__price table thead tr td {
  background: #dedede;
}

.c-product__detail__info .c-product__detail__price table thead tr td span {
  font-size: 12px;
  font-size: 1.2rem;
  font-weight: normal;
}

.c-product__detail__info .c-product__detail__price table thead tr th {
  background: #F3CB01;
}

.c-product__detail__info .c-product__detail__price table tbody tr th {
  font-weight: bold;
}

.c-product__detail__info .c-product__detail__price table tbody tr:nth-child(odd) {
  background: #9E9C9B;
}

.c-product__detail__info .c-product__detail__price table tbody tr:nth-child(even) {
  background: #DEDEDE;
}

.c-product-eco {
  padding-left: 30px;
}

.c-product-eco .p-product-eco__title {
  display: inline-block;
  width: 210px;
  padding: 8px 10px;
  font-size: 15px;
  font-size: 1.5rem;
  font-weight: bold;
  background: #F3CB01;
}

.c-product-eco .c-eco__container {
  flex-wrap: wrap;
}

.c-product-eco .c-eco__container figure {
  width: 100%;
  text-align: center;
}

.c-product-eco .c-eco__msg {
  margin-top: 0;
  border: none;
}

.c-sub-header {
  padding: 1.2em 1em 1.2em 2.4em;
  position: relative;
  border: 2px dashed #73A776;
  color: #73A776;
  font-size: 30px;
  font-size: 3rem;
}

@media screen and (max-width: 767px) {
  .c-sub-header {
    padding: 0.8em 1.2em 0.8em 2.4em;
  }
}

@media screen and (max-width: 479px) {
  .c-sub-header {
    font-size: 20px;
    font-size: 2rem;
  }
}

.c-sub-header::before {
  content: '';
  width: 1.2em;
  height: 1.2em;
  position: absolute;
  top: -2px;
  left: -2px;
  background-image: url(../images/common/sub-title-border.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.c-sub-header::after {
  content: '';
  width: 1.2em;
  height: 1.2em;
  position: absolute;
  right: -2px;
  bottom: -2px;
  background-image: url(../images/common/sub-title-border.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.c-sub-header span {
  position: absolute;
  left: 0.8em;
  top: 50%;
  transform: translateY(-50%);
  line-height: 0;
  width: 1.2em;
}

.c-works-archive__list {
  width: 100%;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.c-works-archive__list .p-content-box {
  width: calc( 33% - 16px);
  margin-bottom: 24px;
  margin-right: 24px;
}

@media screen and (max-width: 479px) {
  .c-works-archive__list .p-content-box {
    width: 100%;
    margin-right: 0;
  }
}

.c-works-archive__list .p-content-box:nth-child(3n) {
  margin-right: 0;
}

.c-works-archive__list .p-content-box h2 {
  margin: 15px 0;
  font-size: 18px;
  font-size: 1.8rem;
  color: #46B5D8;
}

.c-works-archive__list .p-content-box .p-text-box__num {
  display: flex;
}

.c-works-archive__list .p-content-box .p-text-box__num .p-text {
  margin-right: 0.8em;
  padding: 4px 8px 4px 25px;
  position: relative;
  font-size: 12px;
  font-size: 1.2rem;
  font-weight: bold;
  background: #DEDEDE;
}

.c-works-archive__list .p-content-box .p-text-box__num .p-text::before {
  content: "";
  width: 1.2em;
  height: 1.2em;
  position: absolute;
  left: 0.5em;
  top: 50%;
  transform: translateY(-50%);
  background: url(../images/works/pin_icon.png) no-repeat center/contain;
}

.c-works-archive__list .p-content-box .p-text-box__num .p-num {
  font-size: 14px;
  font-size: 1.4rem;
}

.c-works-single__container .c-works__contact {
  padding: 0;
}

.c-works-single__container .c-works-single__cont {
  width: 100%;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 30px;
  padding: 20px;
  letter-spacing: 0;
}

@media screen and (max-width: 991px) {
  .c-works-single__container .c-works-single__cont {
    padding: 20px 0;
  }
}

.c-works-single__container .c-works-single__cont .p-content-box {
  width: 33%;
  margin-bottom: 30px;
}

@media screen and (max-width: 479px) {
  .c-works-single__container .c-works-single__cont .p-content-box {
    width: 100%;
  }
}

.c-works-single__container .c-works-single__cont .p-content-box h2 {
  margin: 15px 0;
  font-size: 18px;
  font-size: 1.8rem;
  color: #46B5D8;
}

.c-works-single__container .c-works-single__cont .p-content-box .p-text-box__num {
  display: flex;
}

.c-works-single__container .c-works-single__cont .p-content-box .p-text-box__num .p-text {
  margin-right: 0.8em;
  padding: 4px 8px 4px 25px;
  position: relative;
  font-size: 12px;
  font-size: 1.2rem;
  font-weight: bold;
  background: #DEDEDE;
}

.c-works-single__container .c-works-single__cont .p-content-box .p-text-box__num .p-text::before {
  content: "";
  width: 1.2em;
  height: 1.2em;
  position: absolute;
  left: 0.5em;
  top: 50%;
  transform: translateY(-50%);
  background: url(../images/works/pin_icon.png) no-repeat center/contain;
}

.c-works-single__container .c-works-single__cont .p-content-box .p-text-box__num .p-num {
  font-size: 14px;
  font-size: 1.4rem;
}

.c-works-single__container .c-works-single__cont .p-single-data {
  width: 60%;
  margin-bottom: 30px;
}

@media screen and (max-width: 991px) {
  .c-works-single__container .c-works-single__cont .p-single-data {
    width: 65%;
  }
}

@media screen and (max-width: 479px) {
  .c-works-single__container .c-works-single__cont .p-single-data {
    width: 100%;
  }
}

.c-works-single__container .c-works-single__cont .p-single-data__container {
  max-width: 400px;
  margin-bottom: 50px;
}

.c-works-single__container .c-works-single__cont .p-single-data__container dl {
  display: flex;
}

.c-works-single__container .c-works-single__cont .p-single-data__container dl dt {
  width: 40%;
  padding: 7px 12px;
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
}

.c-works-single__container .c-works-single__cont .p-single-data__container dl dd {
  flex: 1;
  padding: 7px 12px;
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 1;
  border-top: 1px solid #fff;
  font-weight: 500;
}

.c-works-single__container .c-works-single__cont .p-single-data__container dl:nth-of-type(2n+1) dt,
.c-works-single__container .c-works-single__cont .p-single-data__container dl:nth-of-type(2n+1) dd {
  background: #DEDEDE;
}

.c-works-single__container .c-works-single__cont .p-single-data__container dl:nth-of-type(2n) dt,
.c-works-single__container .c-works-single__cont .p-single-data__container dl:nth-of-type(2n) dd {
  background: #9E9C9B;
}

.c-works-single__container .c-works-single__subcont {
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
  font-size: 1.4rem;
  letter-spacing: 0;
}

.c-works-single__container .c-works-single__subcont p {
  font-size: 14px;
  font-size: 1.4rem;
  letter-spacing: 0;
}

.c-works-single__container .c-works-single__subcont .c-btn-outer {
  margin-top: 60px;
}

.c-works-single__container .c-works-single__subcont .c-btn-outer .c-btn::before, .c-works-single__container .c-works-single__subcont .c-estimate-form .c-btn-outer input[type="submit"]::before, .c-estimate-form .c-works-single__container .c-works-single__subcont .c-btn-outer input[type="submit"]::before {
  content: "\f053";
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.c-sub-works__title {
  display: inline-block;
  width: 220px;
  padding: 8px 10px;
  position: relative;
  z-index: 2;
  font-size: 15px;
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 0;
  background: #F3CB01;
  transform: translateY(50%);
}

.c-estimate-form__container {
  max-width: 760px;
  padding: 30px;
  margin: auto;
  background: #eee;
}

@media screen and (max-width: 991px) {
  .c-estimate-form__container {
    padding: 30px 15px;
  }
}

.c-estimate-form__container .c-must {
  margin-bottom: 20px;
  padding-left: 20px;
  font-size: 14px;
  font-size: 1.4rem;
  color: #F90F04;
}

.c-estimate-form__container dl {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  letter-spacing: 0;
}

.c-estimate-form__container dl dt {
  width: 220px;
  padding: 10px 20px;
  font-size: 15px;
  font-size: 1.5rem;
  font-weight: bold;
}

@media screen and (max-width: 991px) {
  .c-estimate-form__container dl dt {
    padding: 10px 0;
  }
}

@media screen and (max-width: 479px) {
  .c-estimate-form__container dl dt {
    width: 100%;
  }
}

.c-estimate-form__container dl dt span {
  font-size: 10px;
  font-size: 1rem;
  color: #F90F04;
}

.c-estimate-form__container dl dd {
  width: calc( 100% - 220px);
  padding: 10px 20px;
  background: #BEBEBE;
  font-size: 14px;
  font-size: 1.4rem;
}

@media screen and (max-width: 479px) {
  .c-estimate-form__container dl dd {
    width: 100%;
    padding: 10px 0;
    background: none;
  }
}

.c-estimate-form__container dl dd input {
  height: 27px;
  padding: 5px 10px;
  box-sizing: border-box;
  max-width: 100%;
}

.c-estimate-form__container dl dd textarea {
  width: 100%;
  height: 200px;
  padding: 10px;
  box-sizing: border-box;
}

.c-estimate-form__container dl dd .address-text {
  width: 100%;
  margin-top: 15px;
}

.c-estimate-form__container dl dd #zip1,
.c-estimate-form__container dl dd #zip2 {
  display: inline-block;
  width: 6em;
  margin: 0 0.5em;
}

.c-estimate-form__container dl dd input[name="number"] {
  width: 6em;
  margin-right: 0.5em;
}

.c-estimate-form__container dl dd .p-input-75 {
  width: 75%;
}

.c-estimate-form__container dl dd p {
  margin-top: 10px;
  font-size: 12px;
  font-size: 1.2rem;
}

.c-estimate-form .c-btn-outer {
  text-align: center;
  margin: 60px 0;
}

.c-estimate-form .c-btn-outer input[type="submit"] {
  -webkit-transition: 0.16s cubic-bezier(0.17, 0.935, 0.305, 1);
  transition: 0.16s cubic-bezier(0.17, 0.935, 0.305, 1);
  cursor: pointer;
}

.c-guide {
  margin-top: 6em;
  letter-spacing: 0;
}

@media screen and (max-width: 479px) {
  .c-guide {
    margin-top: 3em;
  }
}

.c-guide > h2 {
  text-align: center;
  font-size: 30px;
  font-size: 3rem;
  font-weight: bold;
}

@media screen and (max-width: 479px) {
  .c-guide > h2 {
    font-size: 24px;
    font-size: 2.4rem;
    margin-bottom: 0;
  }
}

.c-guide-list {
  width: 100%;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.c-guide-list li {
  width: 47%;
  margin-bottom: 100px;
}

@media screen and (max-width: 767px) {
  .c-guide-list li {
    width: 100%;
    margin-bottom: 40px;
  }
}

.c-guide-list li .c-guide-list__header {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row nowrap;
  justify-content: center;
  flex-wrap: nowrap;
}

.c-guide-list li .c-guide-list__header .p-point-num {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: nowrap;
  width: 100px;
  height: 100px;
  margin-right: 20px;
  text-align: center;
  font-size: 26px;
  font-size: 2.6rem;
  line-height: 1;
  background: url(../images/guide/point_bg.svg) center/100% 100%;
}

@media screen and (max-width: 479px) {
  .c-guide-list li .c-guide-list__header .p-point-num {
    width: 70px;
    height: 70px;
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.c-guide-list li .c-guide-list__header h3 {
  margin: 0;
  text-align: center;
  font-size: 22px;
  font-size: 2.2rem;
  font-weight: bold;
}

@media screen and (max-width: 479px) {
  .c-guide-list li .c-guide-list__header h3 {
    width: calc(100% - 90px);
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.c-guide-list li .c-guide-list__header h3 span {
  font-size: 90%;
}

.c-guide-list li .c-guide-list__cont {
  margin-top: 40px;
}

.c-guide-list li .c-guide-list__cont p {
  margin-top: 30px;
  font-size: 13px;
  font-size: 1.3rem;
  font-weight: bold;
}

.c-guide-list li:first-child {
  width: 100%;
}

.c-guide-list li:first-child .c-eco > p {
  max-width: 610px;
  margin: auto;
  font-size: 17px;
  font-size: 1.7rem;
}

.c-guide-voice {
  max-width: 840px;
  margin: 100px auto 0;
  padding: 60px 40px;
  background: #D2D2D2;
}

@media screen and (max-width: 479px) {
  .c-guide-voice {
    padding: 60px 15px;
  }
}

.c-guide-voice .c-guide-voice__title {
  text-align: center;
  font-size: 25px;
  font-size: 2.5rem;
  font-weight: bold;
}

.c-guide-voice__container {
  margin-top: 15px;
}

.c-guide-voice__container ul {
  margin-bottom: 70px;
  padding: 0 20px;
  position: relative;
  background: #fff;
  border: 1px solid #707070;
}

@media screen and (max-width: 479px) {
  .c-guide-voice__container ul {
    padding: 0 10px;
  }
}

.c-guide-voice__container ul::before {
  content: '';
  position: absolute;
  bottom: -40px;
  left: calc(50% - 30px);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 40px 30px 0 30px;
  border-color: #707070 transparent transparent transparent;
}

.c-guide-voice__container ul::after {
  content: '';
  position: absolute;
  bottom: -38px;
  left: calc(50% - 30px);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 40px 30px 0 30px;
  border-color: #fff transparent transparent transparent;
}

.c-guide-voice__container ul li {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin: 30px 0;
}

.c-guide-voice__container ul li figure {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: nowrap;
  width: 80px;
  height: 80px;
  margin: 0 40px 0 20px;
  background: #D2D2D2;
  border-radius: 50%;
  line-height: 0;
}

@media screen and (max-width: 479px) {
  .c-guide-voice__container ul li figure {
    width: 50px;
    height: 50px;
    margin: 0 15px 0 0;
  }
  .c-guide-voice__container ul li figure img {
    width: 70%;
  }
}

.c-guide-voice__container ul li .p-voice-work {
  flex: 1;
  border: 1px solid;
  padding: 5px;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 500;
  letter-spacing: 0;
}

.c-guide-voice__container .c-btn-outer .c-btn::after, .c-guide-voice__container .c-estimate-form .c-btn-outer input[type="submit"]::after, .c-estimate-form .c-guide-voice__container .c-btn-outer input[type="submit"]::after {
  content: '\f054';
  position: absolute;
  right: .5em;
  top: 50%;
  transform: translateY(-50%);
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
}

.c-voice__container {
  width: 100%;
  align-items: flex-start;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.c-voice {
  width: 100%;
  letter-spacing: 0;
}

.c-voice ul {
  max-width: 730px;
  margin: auto;
}

.c-voice ul li {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-top: 2em;
}

.c-voice ul li:last-child {
  margin-top: 6em;
}

@media screen and (max-width: 479px) {
  .c-voice ul li {
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }
}

.c-voice ul li .p-voice-user {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: nowrap;
  width: 90px;
  margin-right: 1em;
}

@media screen and (max-width: 767px) {
  .c-voice ul li .p-voice-user {
    margin-right: 0;
    margin-bottom: 1em;
  }
}

.c-voice ul li .p-voice-user .p-voice-img {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: nowrap;
  margin-bottom: 0.5em;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #D2D2D2;
  line-height: 0;
}

.c-voice ul li .p-voice-user figcaption {
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 500;
}

.c-voice ul li .p-voice-content {
  flex: 1;
}

@media screen and (max-width: 479px) {
  .c-voice ul li .p-voice-content {
    flex: none;
    width: 100%;
  }
}

.c-voice ul li .p-voice-content .p-voice-txt {
  margin-bottom: 2em;
  padding: 20px;
  position: relative;
  border: 1px solid #707070;
}

.c-voice ul li .p-voice-content .p-voice-txt::before {
  content: '';
  position: absolute;
  top: 100%;
  left: calc(50% - 20px);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 14px 0 14px;
  border-color: #707070 transparent transparent transparent;
}

.c-voice ul li .p-voice-content .p-voice-txt::after {
  content: '';
  position: absolute;
  top: calc( 100% - 2px);
  left: calc(50% - 20px);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 14px 0 14px;
  border-color: #fff transparent transparent transparent;
}

.c-voice ul li .p-voice-content .p-voice-txt p {
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 500;
}

.c-voice ul li .p-voice-content .p-voice-link {
  display: block;
  text-align: center;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: bold;
  color: #F90F04;
  text-decoration: underline;
}

.c-voice ul li .p-voice-content .c-sub-contact {
  max-width: 100%;
  margin: 0;
  padding: 30px 20px 0;
  border: 1px solid #707070;
}

.c-voice ul li .p-voice-content .c-sub-contact .bold {
  margin-bottom: 1em;
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  .c-voice ul li .p-voice-content .c-sub-contact .bold {
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  .c-voice ul li .p-voice-content .c-sub-contact .bold + p {
    font-size: 0.8em;
  }
  .c-voice ul li .p-voice-content .c-sub-contact .bold + p br {
    display: none;
  }
}

.c-voice ul li .p-voice-content .c-sub-contact .c-btn-outer {
  margin-bottom: 30px;
}

.c-faq {
  width: 100%;
  letter-spacing: 0;
}

.c-faq ul {
  border-top: 5px solid #73A776;
  border-bottom: 5px solid #73A776;
}

.c-faq ul li .c-faq-question {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: nowrap;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 14px 50px 14px 20px;
  position: relative;
  cursor: pointer;
  border-bottom: 1px solid #73A776;
}

@media screen and (max-width: 479px) {
  .c-faq ul li .c-faq-question {
    padding: 14px 35px 14px 5px;
  }
}

.c-faq ul li .c-faq-question::after {
  content: '✛';
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 26px;
  font-size: 2.6rem;
  color: #707070;
}

@media screen and (max-width: 479px) {
  .c-faq ul li .c-faq-question::after {
    right: 5px;
  }
}

.c-faq ul li .c-faq-question p {
  color: #707070;
  font-weight: bold;
  font-size: 18px;
  font-size: 1.8rem;
}

@media screen and (max-width: 767px) {
  .c-faq ul li .c-faq-question p {
    font-size: 15px;
    font-size: 1.5rem;
  }
}

.c-faq ul li .c-faq-question .p-faq-mark {
  margin-right: 30px;
  font-size: 2em;
}

@media screen and (max-width: 479px) {
  .c-faq ul li .c-faq-question .p-faq-mark {
    margin-right: 15px;
  }
}

.c-faq ul li .c-faq-question .p-faq-question-txt {
  flex: 1;
}

.c-faq ul li .c-faq-question.active::after {
  content: '―';
}

.c-faq ul li .p-faq-answer {
  display: none;
  padding: 20px;
  background: #73A776;
  color: #fff;
  font-size: 18px;
  font-size: 1.8rem;
}

@media screen and (max-width: 767px) {
  .c-faq ul li .p-faq-answer {
    font-size: 15px;
    font-size: 1.5rem;
  }
}

.c-company .c-company__container {
  max-width: 660px;
  margin: 100px auto 0;
}

.c-company .c-company__container .c-company__title {
  margin-left: 8px;
  margin-bottom: 15px;
  font-size: 15px;
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 0;
}

.c-company .c-company__container .c-company__table {
  border-top: 2px dashed #707070;
  letter-spacing: 0;
}

.c-company .c-company__container .c-company__table dl {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 2px dashed #707070;
  min-height: 70px;
  padding: 10px 8px;
  font-size: 14px;
  font-size: 1.4rem;
}

.c-company .c-company__container .c-company__table dl dt {
  text-align: center;
  font-weight: bold;
}

.c-company .c-company__container .c-company__table dl dd {
  max-width: 450px;
}

.c-company .c-company__container .c-company__overview__container dl dt {
  width: 60px;
}

.c-company .c-company__container .c-company__overview__container dl dd {
  width: calc(100% - 90px);
}

.c-company .c-company__container .c-company__commercial__container dl dt {
  width: 105px;
}

.c-company .c-company__container .c-company__commercial__container dl dd {
  width: calc(100% - 135px);
}

.c-company .c-company__commercial .c-company__title {
  font-size: 20px;
  font-size: 2rem;
}

.c-equipment__container {
  max-width: 700px;
  margin: 60px auto;
  letter-spacing: -0.04em;
}

.c-equipment__container h3 {
  margin-bottom: 60px;
  text-align: center;
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: bold;
}

.c-equipment__container .c-equipment-cont {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.c-equipment__container .c-equipment-cont .p-equipment-img {
  width: 35%;
  line-height: 0;
}

@media screen and (max-width: 479px) {
  .c-equipment__container .c-equipment-cont .p-equipment-img {
    width: 100%;
    margin-bottom: 15px;
    text-align: center;
  }
}

.c-equipment__container .c-equipment-cont p {
  width: 62%;
  font-size: 12px;
  font-size: 1.2rem;
}

@media screen and (max-width: 479px) {
  .c-equipment__container .c-equipment-cont p {
    width: 100%;
  }
}

.c-equipment .c-process h3 {
  margin-bottom: 60px;
  text-align: center;
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: bold;
}

.c-equipment .c-process__container {
  background: #D2D2D2;
  padding-bottom: 60px;
}

.c-equipment .c-process__container .c-process-item {
  background: #fff;
  letter-spacing: 0;
  opacity: 0.65;
}

.c-equipment .c-process__container .c-process-item figure {
  line-height: 0;
}

.c-equipment .c-process__container .c-process-item .c-process-txt {
  padding: 10px;
}

.c-equipment .c-process__container .c-process-item .c-process-txt p {
  font-size: 12px;
  font-size: 1.2rem;
}

.c-equipment .c-process__container .c-process-item .c-process-txt .p-process-num {
  font-size: 16px;
  font-size: 1.6rem;
  margin-bottom: 10px;
  font-weight: bold;
  text-decoration: underline;
}

.c-equipment .c-process__container .c-process-item.swiper-slide-active {
  opacity: 1;
}

.c-equipment .c-step {
  text-align: center;
  margin-top: 60px;
}

.c-privacy__container h2 {
  font-family: Lusitana, "游明朝", "Yu Mincho",  Shippori , "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", "serif";
  font-weight: 500;
  border-bottom: 1px solid #A29369;
  margin-top: 0.95em;
}

.c-privacy__container ol {
  margin: 40px 0;
  counter-reset: number;
}

.c-privacy__container ol li {
  position: relative;
  font-size: 14px;
  font-size: 1.4rem;
  padding-left: 1.4em;
  margin: 1.2em 0;
}

.c-privacy__container ol li:before {
  position: absolute;
  left: 0;
  counter-increment: number;
  content: counter(number) ".";
  font-size: 1.1em;
  display: block;
}

.c-privacy__container ul {
  margin: 40px 0;
}

.c-privacy__container ul li {
  position: relative;
  font-size: 14px;
  font-size: 1.4rem;
  padding-left: 1.4em;
  margin: 1.2em 0;
}

.c-privacy__container ul li:before {
  position: absolute;
  left: 0;
  top: 0.5em;
  content: " ";
  width: 0.5em;
  height: 0.5em;
  background: #202121;
  display: block;
}

.c-privacy__container p {
  font-size: 14px;
  font-size: 1.4rem;
}
