@charset "utf-8";

/* ======================================================

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

-> scss -> base -> _base.scss

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

====================================================== */

/* ==================================================
   Base Style
   ================================================== */

html {
  font-size: 62.5%;
  //overflow-y: scroll;

  /*-
  @include min-width(md){
    font-size: 71.42857142%;
  }
  -*/
}
body {
  width: 100%;
  margin: 0 auto;
  @include font-size(1.5);
  line-height: 1.6;
  @include font-family;
  font-weight: 400;
  letter-spacing: 0.09em;
  color: $colorBase;
}
a {
  &, &:before, &:after {
    @include transition;
  }

  color: $color-link;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    opacity: 0.8;
    color: $color-link;
  }
}
h1, h2, h3, h4, h5 ,h6 {
  @include font-family;
}
p {
  @include font-family;
}
img {
  max-width: 100%;
	flex-shrink: 0;
}
.cf:after {
  content: "";
  display: block;
  clear: both;
}

/**
 * テキストの選択
 */
::selection {
  background: $color-selection;
}

// for Firefox
::-moz-selection
 {
  background: $color-selection;
}

/**
 * Scroll Bar
 */
// 縦スクロールバー（幅）
::-webkit-scrollbar {
	width: 4px; }

// 横スクロールバー（幅）
::-webkit-scrollbar:horizontal {
	height: 12px; }

// スクロールバー軌道（色）
::-webkit-scrollbar-track {
  background: $color-scrollbar;
}

// 軌道のコーナー（色）
::-webkit-scrollbar-corner {
	background: $color-scrollbar;
}

// 移動タブ（色・角丸み）
::-webkit-scrollbar-thumb {
  background: $color-scrollbar;
	border-radius: 12px;
}

/**
 * iOS
 */
// iOSでのデフォルトスタイルをリセット
input[type="submit"],
input[type="button"] {
  border-radius: 0;
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
    &::-webkit-search-decoration {
      display: none;
    }
    &::focus {
      outline-offset: -2px;
    }
}