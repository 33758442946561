@charset "utf-8";

/* ======================================================

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

-> scss -> module -> _headline.scss

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

====================================================== */


/* ==================================================
   Headline Style
   ================================================== */

// h6 starts at $base-font-size
// headings grow from h6 to h1 by $heading-scale
$base-font-size: 1.2rem;
$heading-scale: 0.4; // amount of px headings grow from h6 to h1
@for $i from 1 through 6 {
    h#{$i} {
        font-size: $base-font-size + $heading-scale * (6 - $i);
        margin-bottom: 0.95em;
    }
}

.heading {
    letter-spacing: 0.06em;
    font-weight: 700;
    color: $color-heading;
    &__container {
        @include flex(space-between, center, row, nowrap);
    }
    &__title {
        @extend .heading;
        width: auto;
        min-width: 360px;
        max-width: calc( 100% - 170px - 20px);
        @include font-size(2.0);
        padding: 0 0 10px 40px;
        background-image: url(../images/common/icon-tri-red.svg);
        background-repeat: no-repeat;
        background-position: left bottom 20px;
        background-size: 30px auto;
        border-bottom: 2px solid #707070;
        @include max-width(xs) {
            min-width: auto;
            max-width: 80%;
        }
        .ja {}
    }
    &__btn {
        // @extend .btn;
        max-width: 170px;
    }
}