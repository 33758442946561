@charset "utf-8";

/* ======================================================

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

-> scss -> module -> _c-flexbox.scss

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

====================================================== */


/* ==================================================
  flexbox Style
================================================== */

.c-flex {
    display: -webkit-flex;
    display: flex;
    &.c-wrap {
        &--wrap {
            flex-wrap: wrap;
        }
        &--nowrap {
            flex-wrap: nowrap;
        }
    }
    &.c-justify {
        &--between {
            -webkit-justify-content: space-between;
            justify-content: space-between;
        }
        &--start {
            -webkit-justify-content: flex-start;
            justify-content: flex-start;
        }
        &--center {
            -webkit-justify-content: center;
            justify-content: center;
        }
        &--end {
            -webkit-justify-content: flex-end;
            justify-content: flex-end;
        }
    }
    &.c-align {
        &--start {
            align-items: flex-start;
        }
        &--center {
            align-items: center;
        }
        &--end {
            align-items: flex-end;
        }
    }
    &.c-direction {
        &--row {
            flex-direction: row
        }
        &--row-r {
            flex-direction: row-reverse;
        }
        &--col {
            flex-direction: column;
        }
        &--col-r {
            flex-direction: column-reverse;
        }
    }
    .c-basis {
        &--auto {
            flex-basis: auto;
        }
    }
    //子要素の左右位置
    .c-self {
        &--left {
            margin-left: 0;
            margin-right: auto;
        }
        &--right {
            margin-right: 0;
            margin-left: auto;
        }
    }
}

@each $breakpoint,
$property in $mq-min-width {
    .flex-min--#{$breakpoint} {
        @include max-width(#{$breakpoint}) {
            display: block !important;
        }
    }
    .flex-max--#{$breakpoint} {
        @include min-width(#{$breakpoint}) {
            display: block !important;
        }
    }
}