@charset "utf-8";

/* ======================================================

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

-> scss -> module -> _button.scss

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

====================================================== */


/* ==================================================
   Button Style
================================================== */

.c-btn {
    outline: none;
    border: none;
    display: inline-block;
    max-width: 100%;
    min-width: 280px;
    position: relative;
    text-align: center;
    color: $colorBase;
    background: #fff;
    padding: 0.8em;
    font-weight: 600;
    @include font-family(sans-serif);
    text-decoration: none;

    @include max-width(sm){
      min-width: auto;
      width: 100%;
    }

    &:hover {
        color: $colorMain;
    }
    &.works {
      .c-icon{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0.8em;
        width: 1.5em;
        fill: currentColor;
      }
    }
    &.angle-right{
      padding-right: 2.8em;

      &::after {
        content: "";
        position: absolute;
        width: 0.6em;
        height: 0.6em;
        right: 1em;
        top: 50%;
        transform: translateY(-50%) rotate(-45deg);
        border-right: 2px solid #000;
        border-bottom: 2px solid #000;
      }

      &:hover{
        &:after{
          border-color: transparent $colorMain $colorMain transparent;
        }
      }
    }

    &.angle-left{
      padding-left: 2.8em;

      &::after {
        content: "";
        position: absolute;
        width: 0.6em;
        height: 0.6em;
        left: 1em;
        top: 50%;
        transform: translateY(-50%) rotate(-45deg);
        border-left: 2px solid #000;
        border-top: 2px solid #000;
      }

      &:hover{
        &:after{
          border-color: $colorMain transparent transparent $colorMain;
        }
      }
    }
}

.c-btn-outer {
    display: block;
    text-align: center;
    margin-top: 4em;

    .c-btn {
        position: relative;
        border: 3px solid;
        @include font-size(1.8);
        font-weight: bold;

        @include max-width(sm){
          @include font-size(1.6);
        }
    }
}