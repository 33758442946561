@charset "utf-8";

/* ======================================================

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

-> scss -> module -> _entry-content.scss

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

====================================================== */


/* ==================================================
  .entry-content
  ================================================== */

.entry-content {
    p {
        display: block;
        margin: 1em 0;
    }
    strong {
        font-weight: bold;
    }
    em {
        font-style: italic;
    }
    blockquote {
        display: block;
        -webkit-margin-before: 1em;
        -webkit-margin-after: 1em;
        -webkit-margin-start: 40px;
        -webkit-margin-end: 40px;
    }
    /* img */
    .aligncenter {
        display: block !important;
        margin: 0 auto !important;
    }
    .alignright {
        float: right;
    }
    .alignleft {
        float: left;
    }
    img[class*="wp-image-"],
    img[class*="attachment-"] {
        height: auto;
        max-width: 100%;
    }
    /* clearfix */
    .clearfix {
        overflow: hidden;
        zoom: 1;
    }
    .clearfix:after {
        content: "";
        display: block;
        clear: both;
    }
}

.entry-content ul:not([class]) li {
    position: relative;
    padding-left: 1em;
    margin: 1em 0;
}

.entry-content ul:not([class]) li:before {
    content: " ";
    width: 9px;
    height: 9px;
    background: #3E3E3E;
    box-shadow: 0 0 20px rgba(51, 51, 51, 0.15) inset;
    display: block;
    position: absolute;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    left: 2px;
    top: 0.5em;
}

.entry-content ul:not([class]) li ul li:before {
    width: 5px;
    height: 5px;
    top: 10px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.entry-content ul:not([class]) li ul li ul li:before {
    width: 14px;
    height: 1px;
    top: 50%;
    @include prefix('transform', translateY(-50%));
    left: -2px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.entry-content ol {
    counter-reset: number;
}

.entry-content ol:not([class]) li {
    list-style: none;
    position: relative;
    padding-left: 2.0em;
    margin: 10px 0;
}

.entry-content ol:not([class])>li:before {
    counter-increment: number;
    content: counter(number);
    background: #3E3E3E;
    box-shadow: 0 0 5em rgba(51, 51, 51, 0.15) inset;
    color: #fff;
    width: 20px;
    height: 20pz;
    font-weight: bold;
    @include font-family(sans-serif);
    font-size: 0.9em;
    display: block;
    text-align: center;
    line-height: 20px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 3px;
}

.entry-content li ol:not([class]) li:before {
    background: #999;
    background: none;
    color: #555;
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 0.9em;
    border-radius: 50%;
    top: 3px;
}


/* entry content */

.entry-content {
    padding: 0 0 1.5em;
    overflow: hidden;
}

#post-not-found .entry-content {
    overflow: inherit;
}

.entry-content p {
    margin: 0 0 1.6em;
    line-height: 1.75;
}

.entry-content table {
    width: 100%;
    font-size: 0.92em;
    border: 1px solid #efefef;
    margin-bottom: 1.5em;
}

.entry-content table caption {
    margin: 0 0 7px;
    font-size: 0.75em;
    color: #9fa6b4;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.entry-content tr {
    border-bottom: 1px solid #efefef;
}

.entry-content td {
    padding: .8em;
    border: 1px solid #cfcfcf;
    background: #fff;
    background: rgba(255, 255, 255, 0.6);
}

.entry-content td input {
    margin: 3px 0;
}

.entry-content th {
    background-color: #eee;
    border: 1px solid #dedede;
    padding: .8em;
}

.entry-content blockquote {
    padding: 20px 3%;
    margin: 2.5em 0 3em;
    position: relative;
    opacity: .8;
    filter: alpha(opacity=80);
    -ms-filter: "alpha(opacity=90)";
    display: block;
    text-align: center;
    font-size: 1.3em;
    line-height: 1;
    background: #fff;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}

.entry-content blockquote:before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background: url('../img/top/blockquote-start.svg') no-repeat left top;
    background-size: contain;
}

.entry-content blockquote:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    margin-left: auto;
    margin-right: 0;
    background: url('../img/top/blockquote-end.svg') no-repeat left top;
    background-size: contain;
}

.entry-content blockquote h1,
.entry-content blockquote h2,
.entry-content blockquote h3,
.entry-content blockquote h4 {
    margin-top: 0!important;
}

.entry-content dt {
    font-weight: bold;
    margin-bottom: 2%;
}

.entry-content dd {
    margin-left: 0;
    font-size: .95em;
    margin-bottom: 4%;
}

.entry-content img {
    max-width: 100%;
    height: auto;
}

.entry-content .blocks-gallery-item img {
    margin-bottom: 0;
}

.entry-content .size-auto,
.entry-content .size-full,
.entry-content .size-large,
.entry-content .size-medium,
.entry-content .size-thumbnail {
    max-width: 100%;
    height: auto;
}

.entry-content pre {
    background: #666;
    background: rgba(0, 0, 0, 0.54);
    color: #f8f9fa;
    font-size: 0.88em;
    padding: 1.2em 1.7em;
    margin: 0.2em 0 2.2em;
    border-radius: 5px;
}

.entry-content .wp-block-image figcaption {
    display: block;
    width: 100%;
    margin-top: .5em !important;
    color: #555d66 !important;
    text-align: center !important;
    font-size: 13px !important;
}

.wp-caption {
    max-width: 100%;
    background: #fff;
    padding: 5px;
    margin-bottom: 1.8em;
    border: 1px solid rgba(0, 0, 0, .08);
    border-radius: 3px;
}

.wp-caption img {
    max-width: 100%;
    margin-bottom: 0;
    width: 100%;
}

.wp-caption p.wp-caption-text {
    font-size: 0.75em;
    margin: 4px 0;
    text-align: center;
}

iframe {
    max-width: 100%;
}

iframe.wp-embedded-content {
    width: 100%;
}

.entry-content {
    h1 a,
    .h1 a,
    h2 a,
    .h2 a,
    h3 a,
    .h3 a,
    h4 a,
    .h4 a,
    h5 a,
    .h5 a {
        text-decoration: none;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @include font-family(sans-serif);
    }
    h1,
    .h1 {
        font-size: 1.5em;
        font-weight: bold;
        line-height: 1.333em;
    }
    h2,
    .h2 {
        font-size: 1.5em;
        line-height: 1.4em;
        font-weight: bold;
        margin-bottom: 0.375em;
    }
    h3,
    .h3 {
        font-size: 1.3em;
        font-weight: bold;
    }
    h4,
    .h4 {
        font-size: 1.2em;
        font-weight: 700;
    }
    h5,
    .h5 {
        font-size: 1em;
        font-weight: 700;
        line-height: 2em;
        text-transform: uppercase;
        letter-spacing: 1px;
    }
}

.entry-content h2 {
    position: relative;
    border: none;
    font-size: 1.25em;
    padding: 1em 1.1em;
    margin-top: 2.1em;
    margin-bottom: 1em;
    background: $colorMain;
    color: #fff;
}

.entry-content h3 {
    position: relative;
    border: none;
    font-size: 1.25em;
    padding: 0.7em 0.9em;
    margin-bottom: 1em;
    background: rgba( $colorBorder, 0.4 );
    color: $colorBase;
    margin-top: 1.8em;
}

.entry-content h4 {
    margin-top: 1.5em;
    margin-bottom: 1em;
}

.entry-content h5 {
    margin-top: 1.5em;
    margin-bottom: 1em;
}