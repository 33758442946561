@charset "utf-8";

/* ======================================================

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

-> scss -> _config.scss

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

====================================================== */

/**
 * Mediaquery
 */

$mq-min-width: (
  'xlg': 1200,
  'lg': 1080,
  'md': 992,
  'sm': 768,
  'xs': 480,
);
$mq-max-width: (
  'xlg': 1199,
  'lg': 1079,
  'md': 991,
  'sm': 767,
  'xs': 479,
);
$mq-unit: 'px';

/**
 * Font Size
 */
$font-size-init: 1.4;

/**
 * Font Family
 */

$fonts:(
  'sans-serif': (
    fontname: '"Lato", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro W3","ヒラギノ角ゴ", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", "sans-serif"',
    fontweight: 500
  ),
  'serif': (
    fontname: 'Lusitana, "游明朝", "Yu Mincho", 'Shippori', "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", "serif"',
    fontweight: 500
  ),
  'times-new-roman': (
    fontname: '"Times New Roman", "serif"',
    fontweight: 500
  ),
  'reem-kufi': (
    fontname: '"Reem Kufi Regular" !important',
    fontweight: 500
  ),
  'din-condensed': (
    fontname: '"DIN Condensed Bold" !important',
    fontweight: 500
  ),
  'lato': (
    fontname: '"Lato Regular" !important',
    fontweight: 500
  ),
  'satisfy': (
    fontname: '"Satisfy Regular" !important',
    fontweight: 400
  ),
  'patua-one': (
    fontname: '"Patua One Regular" !important',
    fontweight: 400
  ),
  'cabin-sketch': (
    fontname: '"Cabin Sketch Regular" !important',
    fontweight: 400
  ),
  'literata-book': (
    fontname: '"Literata Book Regular" !important',
    fontweight: 400
  ),
  'kumar-one-outline': (
    fontname: '"Kumar One Outline" !important',
    fontweight: 400
  ),
  'diplomata': (
    fontname: '"Diplomata" !important',
    fontweight: 400
  ),
  'yeseva-one': (
    fontname: '"Yeseva One" !important',
    fontweight: 400
  ),
  'vast-shadow': (
    fontname: '"Vast Shadow" !important',
    fontweight: 400
  ),
  'averia-serif-libre': (
    fontname: '"Averia Serif Libre" !important',
    fontweight: 400
  ),
  'midashi-go': (
    fontname: 'a-otf-midashi-go-mb31-pr6n, sans-serif !important',
    fontweight: 600
  ),
  'modern-antiqua': (
    fontname: 'Modern Antiqua !important',
    fontweight: 600
  ),
  'lusitana': (
    fontname: 'Lusitana !important',
    fontweight: 500
  ),
);

/**
 * Inner
 */
$inner-max-width: 1080;
$inner-pd: 5%;

/**
 * Image-trim
 */
$image-trim-pb: (
  '2ls': 100%,
  'ld': 75%,
  'lvh': 56.25%,
  'l': 66.6666%,
  'before-after': 153.6%,
);

/**
 * border-radius
 */
$post-image-radius: 20px;
