@charset "utf-8";

// // ---------aside仮----------------------------

// .inner{
//     display: flex;
// }
// .aside{
//   width: 100%;
//   max-width: 220px;
//   height: 2000px;
//   background-color:red;
//   margin-right: 2em;
//   z-index: 2000;

//     @include max-width(md){
//     display: none;
//     }
// }

// ---------blog----------------------------

.c-blog__container{


    h1{
        @include font-size(3.8);
        border: 2px dashed #73A776;
        padding:0.7em 0 0.7em 3.6em;
        max-width: 840px;

        position: relative;
        color: #73A776;
        margin-bottom: initial;

            @include max-width(sm){
                @include font-size(2.6);

            }
        span{
        position: relative;
        }

        span:before{
            content: "";
            background-image: url("../images/icon/blog.png");
            position: absolute;
            left: -1.5em;
            top: 0.15em;
            width: 1em;
            height: 1em;
            display: block;
            background-size: cover;
        }
    }
    h1:before{
        content: "";
        left: -1.1em;
        top: -1em;
        position: absolute;
        width: 2em;
        background: #fff;
        height: 1.9em;
        border-right: 2px dashed #73A776;
        transform: rotate(45deg);

    }
    h1:after{
        content: "";
        right: -1.1em;
        bottom: -1em;
        position: absolute;
        width: 2em;
        background: #fff;
        height: 1.9em;
        border-left: 2px dashed #73A776;
        transform: rotate(45deg);

    }
    .p-news-info{
      @include font-size(1.6);
      font-weight: bold;
      max-width: 642px;

        @include max-width(sm){
            @include font-size(1.5);
        }

    }

    .c-blog__container__article{
        max-width: 760px;
        margin: 0 auto;

        .p-news-box{

            padding: 2em 0;
            border-bottom : dashed 2px #707070;
            @include max-width(sm){
                padding: 1em 0;
            }
            a{
                @include flex(flex-start,  row, wrap);
                color: inherit;

                .p-img-box.c-trim{
                    width: 200px;
                    position: relative;
                    overflow: hidden;
                    height: 200px;

                    @include max-width(sm){
                    max-width: 130px;
                    height: 130px;
                    }

                    .c-trim__img{

                        display: block;
                        width: 100%;
                        height: 100%;
                        object-position: center;
                        object-fit: cover;
                        font-family: 'object-fit: cover;'
                    }
                }

                .p-news-box__text{
                    max-width: 412px;
                    padding-left: 2em;
                    flex:1;
                    @include max-width(sm){
                        @include font-size(1.2);
                    }

                    .p-news-box__title{

                        @include font-size(1.6);
                        font-weight: bold;
                        display: block;
                        padding-bottom: 0.6em;
                        margin-bottom: 0;
                        @include max-width(sm){
                            display: none;
                        }
                    }
                    .p-date{
                        @include font-size(1.5);
                        font-weight: bold;
                        margin-bottom: 0.5em;

                        @include max-width(sm){
                            @include font-size(1.2);
                            display: inline;
                            padding-right: 1em;
                        }
                    }

                    .p-news-box__category{
                        @include font-size(1.5);
                        background-color: #fff;
                        border:1px solid #707070 ;
                        display: inline;
                        padding: 0.3em 1em;

                        @include max-width(sm){
                            @include font-size(1.3);
                        }
                    }
                    .p-news-box__desc{
                        @include font-size(1.2);
                        padding-top: 1em;

                    }
                }
            }
        }
    }
}


.c-pagenavi{
    text-align: center;
    padding: 3em 5em 12em;

}


// ---------post----------------------------

.p-post{

    .p-post__title{
        border-bottom : dashed 2px #707070;
        @include font-size(2.4);
        font-weight: bold;


        @include max-width(sm){
            @include font-size(2.0);
        }

    }
    .p-post-box{
        display: flex;
        padding-bottom: 1.8em;

        @include max-width(md){

            display:flex;
            flex-direction:column;

            }

        .p-img-box.c-trim{
            width: 314px;
            position: relative;
            overflow: hidden;
            height: 200px;

            @include max-width(md){
            max-width: 314px;
            margin: 0 auto;
            order: 2;

            }

            .c-trim__img{

                display: block;
                width: 100%;
                height: 100%;
                object-position: center;
                object-fit: cover;
                font-family: 'object-fit: cover;'
            }
        }

        &__text{
            padding-top: 2em;
            flex:1;

            @include max-width(md){
                order: 1;
                right: 0;;
                padding: initial;
                padding-bottom: 1em;
                margin-left: auto;
                }



            .p-date{
                display: inline;
                padding:0 2em;
                @include font-size(1.5);


                @include max-width(md){
                    @include font-size(1.2);
                    padding-bottom: 1em;
                }

            }
            .p-post__category {
                display: inline-block;
                @include font-size(1.6);
                background-color: #fff;
                border:1px solid #707070 ;
                padding: 2px 16px;


            }
        }
    }

    .p-post__desc{
        @include font-size(1.4);
        padding-bottom:2em;
        font-weight: initial;
        line-height: 1.6666666;

    }
}

// ---------flow----------------------------

.c-flow__container {
    width: 100%;
    max-width: 840px;

    h1{
        @include font-size(3.8);
        border: 2px dashed #73A776;
        padding:0.7em 0 0.7em 3.6em;
        max-width: 840px;

        position: relative;
        color: #73A776;
        margin-bottom: initial;

            @include max-width(sm){
                @include font-size(2.6);

            }
        span{

        position: relative;
        }

        span:before{
            content: "";
            background-image: url("../images/icon/flow.png");
            position: absolute;
            left: -1.5em;
            top: 0.15em;
            width: 1em;
            height: 1em;
            display: block;
            background-size: cover;
        }
    }
    h1:before{
        content: "";
        left: -1.1em;
        top: -1em;
        position: absolute;
        width: 2em;
        background: #fff;
        height: 1.9em;
        border-right: 2px dashed #73A776;
        transform: rotate(45deg);

    }
    h1:after{
        content: "";
        right: -1.1em;
        bottom: -1em;
        position: absolute;
        width: 2em;
        background: #fff;
        height: 1.9em;
        border-left: 2px dashed #73A776;
        transform: rotate(45deg);

    }

    .c-flow__step{
       padding: 3.6em 0 4em 0;

        @include max-width(sm){
            @include font-size(2.6);
            padding: 1em 0 1.2em 0;
        }

        &__text{
            @include font-size(3.0);
            font-weight: bold;
            text-align: center;
            padding-bottom:1em ;


            @include max-width(sm){
                @include font-size(2.0);

            }
        }
    }
}

.c-flow__list{

    li{
        border: 1px solid #707070;
        position: relative;
        margin-bottom: 6em;

        @include max-width(sm){
            margin-bottom: 3em;
        }

        .c-flow-box{
            max-width: 800px;
            padding: 2em 3em 1em 10em;
            position: relative;

            @include max-width(sm){
                padding: 3em 1rem 0 1rem;
            }

            .c-heading-03{
                @include font-size(1.4);
                font-weight: bold;

                @include max-width(sm){
                    margin-bottom: 1em;
                    text-align: center;
                    @include font-size(2.8);
                }

            }
            p{
                @include font-size(1.6);

                @include max-width(sm){
                    @include font-size(1.4)
                }
            }
            .c-tel-img-box{
                  padding: 1em 0;
                @include max-width(sm){
                  display: none;

                }
            }

        }
        .c-btn-outer{
            text-align: center;
            padding-bottom: 2em;

            @include max-width(sm){
                padding: 1em 0 2em 0;
            }
            .c-btn{
                @include font-size(1.8);
                color:#73A776;
                background: #fff;
                display: inline-block;
                cursor: pointer;
                position: relative;
                border:3px solid #73A776;
                padding: 1em 4em;
                font-weight: bold;

                    @include max-width(sm){
                        margin-left: 10px;
                        @include font-size(1.4);
                    }
            }
            .c-btn:after{
                content: "";
                background-image: url("../images/main/sign_icon_right.png");
                color:  #73A776;
                position: absolute;
                right: 1em;
                top: 1.2em;
                width: 1em;
                height: 1em;
                display: block;
                background-size: cover;
            }
        }
    }
    li:nth-child(n+2){

        .c-flow-box{
            padding: 5.5em 3em 5.5em 10em;

            @include max-width(sm){
                padding: 3em 2rem 3rem 2rem;
            }

        }

    }

    li:nth-child(1):before{
        content: "";
        background-image: url("../images/main/flow_step1.png");
        position: absolute;
        left: 3.5em;
        top: 50%;
        width: 50px;
        height: 100px;
        display: block;
        background-size: cover;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);

        @include max-width(sm){
            top: 5em;
            @include font-size(1.2)
        }
    }
    li:nth-child(2):before{
        content: "";
        background-image: url("../images/main/flow_step2.png");
        position: absolute;
        left: 3.5em;
        top: 50%;
        width: 50px;
        height: 100px;
        display: block;
        background-size: cover;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);

        @include max-width(sm){
            top: 5em;
            @include font-size(1.2)
        }
    }
    li:nth-child(3):before{
        content: "";
        background-image: url("../images/main/flow_step3.png");
        position: absolute;
        left: 3.5em;
        top: 50%;
        width: 50px;
        height: 100px;
        display: block;
        background-size: cover;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);

        @include max-width(sm){
            top: 5em;
            @include font-size(1.2)
        }
    }
    li:nth-child(4):before{
        content: "";
        background-image: url("../images/main/flow_step4.png");
        position: absolute;
        left: 3.5em;
        top: 50%;
        width: 50px;
        height: 100px;
        display: block;
        background-size: cover;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);

        @include max-width(sm){
            top: 5em;
            @include font-size(1.2)
        }
    }
    li:nth-child(5):before{
        content: "";
        background-image: url("../images/main/flow_step5.png");
        position: absolute;
        left: 3.5em;
        top: 50%;
        width: 50px;
        height: 100px;
        display: block;
        background-size: cover;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);

        @include max-width(sm){
            top: 5em;
            @include font-size(1.2)
        }
    }
    li:not(:last-child):after{
        content: "";
        background-image: url("../images/main/sign_icon_btm.png");
        position: absolute;
        left: 50%;
        bottom: -3.5em;
        width: 1.5em;
        height: 1.5em;
        display: block;
        background-size: cover;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);

        @include max-width(sm){
            bottom: -2.5em;

        }
    }
}

// ---------works----------------------------


.c-works{

    .c-works__container {
        max-width: 830px;
        width: 100%;


        h1{
            @include font-size(3.8);
            border: 2px dashed #73A776;
            padding:0.7em 0 0.7em 3.6em;
            max-width: 840px;

            position: relative;
            color: #73A776;
            margin-bottom: initial;

                @include max-width(sm){
                    @include font-size(2.6);
                }

            span{
                position: relative;
            }

            span:before{
                content: "";
                background-image: url("../images/icon/works.png");
                position: absolute;
                left: -1.5em;
                top: 0.15em;
                width: 1em;
                height: 1em;
                display: block;
                background-size: cover;
            }
        }

        h1:before{
            content: "";
            left: -1.1em;
            top: -1em;
            position: absolute;
            width: 2em;
            background: #fff;
            height: 1.9em;
            border-right: 2px dashed #73A776;
            transform: rotate(45deg);

        }
        h1:after{
            content: "";
            right: -1.1em;
            bottom: -1em;
            position: absolute;
            width: 2em;
            background: #fff;
            height: 1.9em;
            border-left: 2px dashed #73A776;
            transform: rotate(45deg);

        }


        .c-works__contact{
            text-align: center;
            padding: 4em 0 4.6em 0;

            @include max-width(sm){
                @include font-size(2.6);
                padding: 1em 0 1.2em 0;
            }
        }

        ul{

        width: 100%;
        @include flex(space-between, center, row, wrap);

            li{
                margin-right: 25px;
                width: calc( 33.3333% - 25px);

                    @include max-width(md){
                        width: calc( 50% - 10px);
                        margin-right: 10px;
                    }

                a{
                    color: inherit;

                    .p-img-box.c-trim{
                        width: 100%;
                        position: relative;
                        overflow: hidden;
                        height: 200px;

                        @include max-width(md){
                        max-width: 240px;
                        margin: 0 auto;

                        }

                        .c-trim__img{

                            display: block;
                            width: 100%;
                            height: 100%;
                            object-position: center;
                            object-fit: cover;
                            font-family: 'object-fit: cover;'
                        }
                    }
                    .p-text-box{

                        h2{
                            @include font-size(1.8);
                            color: #46B5D8;
                            padding: 0.75em 0;
                            margin: initial;

                            @include max-width(sm){
                                @include font-size(1.6);
                             }

                        }
                        .p-text-box__num{
                            display: flex;

                            @include max-width(sm){
                               justify-content: center;

                            }

                            .p-text{
                                @include font-size(1.2);
                                font-weight: bold;
                                background-color: #DEDEDE;
                                margin-right: 0.8em;
                                padding: 4px 8px 4px 27px;
                                position: relative;
                            }
                            .p-text:before{
                                content: "";
                                background-image: url("../images/works/pin_icon.png");
                                color:  #73A776;
                                position: absolute;
                                left: 0.8em;
                                top: 0.6em;
                                width: 1em;
                                height: 1em;
                                display: block;
                                background-size: cover;
                            }
                            .p-num{
                                @include font-size(1.4);
                            }
                        }
                    }
                }
            }

            li:nth-child(3n){
                margin-right: initial;

            }

            li:nth-child(2n){

                @include max-width(md){
                    margin-right: initial;

                }
            }

            li:nth-child(n+4){
                margin-top: 40px;
            }

            li:nth-child(n+4){
                margin-top: 40px;

            }
            li:nth-child(n+3){

                @include max-width(md){
                    margin-top: 40px;

                }
            }
        }
    }
}


// ---------sitemap----------------------------
.c-sitemap{

    .inner{
      display: inline;;


        h1{
            text-align: center;
            @include font-size(3.0);
            padding-bottom: 1.8em;
            margin-bottom: initial;

        span{
            background: linear-gradient(transparent 70%, #73A776 70%);
            font-weight: bold;
        }

        }
        .c-sitemap__container {
            width: 100%;
            max-width: calc( 1080px + 5% * 2);
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            padding-left: 5%;
            padding-right: 5%;
            margin-left: auto;
            margin-right: auto;

            .c-sitemap__container__inner {


                display: flex;
                justify-content: center;
                max-width: 540px;
                margin: 0 auto;

                .c-sitemap__block{
                    width: 50%;

                    ul{

                        li{

                            @include font-size(1.4);
                            font-weight:bold;

                            &:nth-child(n+2){
                                padding-top: 0.7em;

                            }
                            a{
                                color: inherit;
                            }
                        }
                    }
                }
            }
        }
    }
}

// ---------privacypolicy----------------------------

.c-privacy__container{

    h1 {

        @include font-size(2.0);
        font-weight:bold;
        margin-bottom: 3em;
    }

    h2{
        @include font-size(1.8);
        font-weight:bold;
        margin-top: 1em;
        margin-bottom: initial;
    }


    p {
        @include font-size(1.4);
    }
}


// ---------privacypolicy----------------------------

.c-voice{

    .c-voice__container{

        h1{
            @include font-size(3.8);
            border: 2px dashed #73A776;
            padding:0.7em 0 0.7em 3.6em;
            max-width: 840px;

            position: relative;
            color: #73A776;
            margin-bottom: initial;

                @include max-width(sm){
                    @include font-size(2.6);
                }

            span{
                position: relative;
            }

            span:before{
                content: "";
                background-image: url("../images/icon/works.png");
                position: absolute;
                left: -1.5em;
                top: 0.15em;
                width: 1em;
                height: 1em;
                display: block;
                background-size: cover;
            }
        }

        h1:before{
            content: "";
            left: -1.1em;
            top: -1em;
            position: absolute;
            width: 2em;
            background: #fff;
            height: 1.9em;
            border-right: 2px dashed #73A776;
            transform: rotate(45deg);

        }
        h1:after{
            content: "";
            right: -1.1em;
            bottom: -1em;
            position: absolute;
            width: 2em;
            background: #fff;
            height: 1.9em;
            border-left: 2px dashed #73A776;
            transform: rotate(45deg);

        }


        .c-works__contact{
            text-align: center;
            padding: 4em 0 4.6em 0;

            @include max-width(sm){
                @include font-size(2.6);
                padding: 1em 0 1.2em 0;
            }
        }

        .c-voice__lists{

           ul{

            padding-top: 3em;



               li{
                   max-width: 620px;
                   margin-left: auto;
                   text-align: center;


                    .p-voice-text{
                        @include font-size(1.8);
                        position: relative;
                        display: inline-block;
                        padding: 1em;
                        min-width: 120px;
                        max-width: 100%;
                        border: solid 3px #555;
                        box-sizing: border-box;



                    }
                    .p-voice-text:before {
                        content: "";
                        position: absolute;
                        bottom: -24px;
                        left: 50%;
                        margin-left: -15px;
                        border: 12px solid transparent;
                        border-top: 12px solid #FFF;
                        z-index: 2;
                    }

                    .p-voice-text:after {
                        content: "";
                        position: absolute;
                        bottom: -30px;
                        left: 50%;
                        margin-left: -17px;
                        border: 14px solid transparent;
                        border-top: 14px solid #555;
                        z-index: 1;
                    }

                    .p-voice-link{

                        @include font-size(1.8);
                        border-bottom:1px solid #F90F04;
                        color: #F90F04;
                        display: inline-block;
                        padding-top: 2em;
                        font-weight: bold;

                    }




               }

               li:not(:last-child){
                   padding-bottom: 2em;
               }



           }


        }
        .c-voice__contact{
            max-width: 640px;
            margin-left: auto;
            margin-top: 3em;
            padding: 2em 1.8em 2em 1.1em;
            border: solid 1px #030303;

            h2{
                @include font-size(1.8);
                font-weight: bold;
            }
            .p-contact__text-top {
                @include font-size(1.8);
                padding-top: 2em;

            }
            .c-tel-img-box{
                padding-top: 2em;

            }
            .c-btn-outer{
                text-align: center;
                padding-top: 2em;

            }
            .p-contact__text-btm{
                padding-top: 2em;
                @include font-size(1.8);
                font-weight: bold;
            }
        }


    }
    .c-voice__container-box{
        margin-top: 5em;

        .c-voice__menu{
            margin-top: 1em;
        }



    }
}

